/* footer */
footer {
    background: #293333 url(../img/img-4.png);
    font-family: $light;
    font-size: 18px;
    color: #d5d7d7;
    padding-top: 120px;
    ul {
        @include ul-default;
        li {
            margin-top: 0;
            margin-bottom: 13px;
            &.active a {
                color: #ff4d00;
            }
        }
    }
    .arr--list {
        li {
            line-height: 1.2;
            margin-top: 0;
        }
    }
    h3 {
        width: 100%;
        color: #ffffff;
        text-transform: uppercase;
        margin: 0 0 20px;
    }
    a {
        color: #d5d7d7;
        &:focus {
            color: #d5d7d7;
        }
        &:hover {
            color: #ff4d00;
        }
    }
    > .container {
        display: flex;
        justify-content: space-between;
    }
    .footer__item {
        padding-right: 15px;
        &.f--crs {
            display: flex;
            flex-wrap: wrap;
            align-items:flex-start;
            width: 320px;
            justify-content: space-between;
            ul {
                @include inline-block;
                padding-right: 25px;
                &:last-of-type {
                    padding-right: 0;
                }
            }
            .scrollY {
                max-height: 235px;
                max-width: 100%;
                margin-right: 5px;
                .mCSB_scrollTools {
                    width: 4px;
                }
                .mCSB_scrollTools .mCSB_draggerContainer {
                    background-color: #fff;
                }
                .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
                    margin: 0;
                }
                .mCSB_scrollTools .mCSB_draggerRail {
                    display: none;
                }
            }
        }
        &.f--cts {
            padding-right: 0;
            .col--phone a {
                margin-bottom: 5px;
            }
        }
    }
    .down {
        background-color: #191f1f;
        font-size: 14px;
        margin-top: 110px;
        .container {
            height: 52px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
        }
        .socials {
            display: flex;
            width: 160px;
            justify-content: space-between;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
            margin: 0 auto;
            li {
                margin: 0;
                a {
                    font-size: 24px;
                }
            }
        }
        p {
            margin: 0;
            span {
                color: #d14406;
            }
        }
        .artmedia {
            display: flex;
            align-items: center;
        }
    }
    @media screen and (max-width: 1440px) {
        font-size: 16px;
        padding-top: 100px;
        .down {
            margin-top: 90px;
        }
    }
    @media screen and (max-width: 1366px) {
        padding-top: 80px;
        .down {
            margin-top: 70px;
        }
    }
    @media screen and (max-width: 1280px) {
        padding-top: 60px;
        .down {
            margin-top: 40px;
        }
    }
    @media screen and (max-width: 1200px) {
        font-size: 14px;
        .footer__item {
            &.f--crs {
                width: 425px;
            }
        }
        .down {
            .socials {
                width: 140px;
                li a {
                    font-size: 22px;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding-top: 40px;
        .down {
            margin-top: 30px;
            .socials {
                width: 120px;
                li a {
                    font-size: 20px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        padding-top: 30px;
        > .container {
            flex-wrap: wrap;
        }
        h3 {
            font-size: 16px;
        }
        .footer__item {
            width: 100%;
            margin-bottom: 15px;
            &.f--crs {
                width: 100%;
                flex-wrap: wrap;
                ul {
                    width: 100%;
                    padding-right: 0;
                }
            }

            &.f--nav {
                display: none;
            }
            &.f--cts {
                margin-bottom: 0;
            }
        }
        .down {
            margin-top: 15px;
            .container {
                height: auto;
                flex-direction: column;
                padding: 15px;
            }
            .socials {
                position: relative;
                top: 0;
                transform: translateY(0);
                margin: 8px auto;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .footer__item {
            &.f--crs {
                flex-wrap: wrap;
                ul {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}
/* footer */