/* page content */
.page {
	.news1, .news {
		.date {
			color: #ff4d00;
		}
	}
	.page_nav {
		.active {
			color: #ff4d00;
		}
	}
    #ask-form {
        max-width: 350px;
    }
    .write {
        color: #ff4d00;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .back_link {
        margin: 25px 0;
    }
    .bg--white, .bg--gray {
        padding-bottom: 115px;
        padding-top: 70px;
    }
    &.page--about .bg--white {
        padding-top: 115px;
    }
    p a {
        color: #ff4d00;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    h2 a {
        font-size: 16px;
        color: #ff4d00;
    }
    @media screen and (max-width: 1440px) {
        .bg--white, .bg--gray {
            padding-bottom: 100px;
            padding-top: 70px;
        }
    }
    @media screen and (max-width: 1200px) {
        .bg--white, .bg--gray {
            padding-bottom: 75px;
            padding-top: 50px;
        }
    }
	@media screen and (max-width: 1024px) {
		#photos-list td img {
			width: 100%;
			height: auto;
		}
        .bg--white, .bg--gray {
            padding-bottom: 50px;
            padding-top: 30px;
        }
	}
    @media screen and (max-width: 768px) {
        .bg--white, .bg--gray {
            padding-top: 20px;
            padding-bottom: 30px;
        }
    }
	@media screen and (max-width: 480px) {
		p img, p strong img, h2 img, h3 img, h4 img, h5 img, h6 img, p a img, strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
        #page-text p a img {
            width: auto!important;
            max-width: 100%!important;
        }
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
    font-weight: normal;
	font-size: 14px;
    padding: 30px 0 40px;
	ul {
        min-height: 40px;
		@include ul-default;
        margin-bottom: 15px;
		li {
			padding: 5px 0px;
			color: #ff4d00;
			@include inline-block;
			a {
                color: #cccccc;
				text-decoration: none;
                &:hover {
                    color: #ff4d00;
                }
			}
			&:after {
				content: '/';
                font-size: 14px;
				margin: 1px 3px 0px 8px;
				color: #cccccc;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
    h1 {
        color: #fff;
        text-transform: inherit;
        margin: 0;
    }
    @media screen and (max-width: 1200px) {
        padding: 20px 0 30px;
        ul {
            min-height: 0px;
            margin-bottom: 10px;
        }
    }
    @media screen and (max-width: 1024px) {
        font-size: 14px;
        padding: 15px 0 20px;
        ul {
            li:after {
                font-size: 12px;
                margin: 2px 5px 0 7px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        font-size: 12px;
        ul li {
            padding-top: 2px;
            padding-bottom: 2px;
            &:after {
                font-size: 12px;
            }
        }
        h1 {
            font-size: 20px;
        }
    }
}
/* breadcrumbs */

/* page about */
.page {
    .about__slogan {
        display: block;
        margin-top: -60px;
        margin-bottom: 35px;
    }
    &.page--about {
        .bg--white {
            padding-bottom: 0;
            border-bottom: 115px solid #fff;
        }
        .container.figure {
            display: block;
            overflow: visible;
            &:before {
                width: 150vw;
                left: -15rem;
                box-shadow: -3px -3px 3px rgba(0,0,0,0.06);
            }
            &.left {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        &.page--about {
            .bg--white {
                padding-top: 100px;
                border-bottom: 100px solid #fff;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        &.page--about {
            .container.figure {
                &.left {
                    padding-top: 0;
                    padding-bottom: 0;
                }
                &.white {
                    background-color: #fff;
                }
                &:before {
                    display: none;
                }
            }
            .bg--white {
                padding-top: 75px;
                border-bottom: 75px solid #fff;
            }
        }
        .about__slogan {
            margin: 0 0 20px;
        }
    }
    @media screen and (max-width: 1024px) {
        &.page--about {
            .bg--white {
                padding-top: 30px;
                border-bottom: 50px solid #fff;
            }
        }
    }
    @media screen and (max-width: 768px) {
        &.page--about {
            .bg--white {
                padding-top: 20px;
                border-bottom: 30px solid #fff;
            }
        }
    }
}
.about__info {
    display: flex;
    margin-bottom: 30px;
    .left {
        display: flex;
        width: 485px;
        align-items: center;
        padding-right: 100px;
        img {
            display: block;
            max-width: 100%;
        }
    }
    .right {
        width: calc(100% - 485px)
    }
    .about__list {
        display: flex;
        ul {
            min-width: 30%;
            padding-right: 15px;
        }
    }
    @media screen and (max-width: 1200px) {
        .left {
            width: 385px;
            padding-right: 50px;
        }
        .right {
            width: calc(100% - 385px);
        }
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 20px;
        .left {
            width: 285px;
        }
        .right {
            width: calc(100% - 285px);
        }
    }
    @media screen and (max-width: 600px) {
        flex-wrap: wrap;
        .left {
            width: 100%;
            padding-right: 0;
            img {
                display: block;
                margin: 0px auto 15px;
            }
        }
        .right {
            width: 100%;
        }
    }
    @media screen and (max-width: 480px) {
        .about__list {
            flex-wrap: wrap;
            ul {
                width: 100%;
                padding-right: 0;
            }
            .arr--list {
                li {
                    margin: 0 0 10px;
                }
            }
        }
    }
}
/* page about */

/* page schedule */
.tabs {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    line-height: 1;
    margin-bottom: 30px;
    ul {
        @include ul-default;
        display: flex;
        width: calc(100% - 345px);
        border-bottom: 1px solid #e2e3e3;
        li {
            margin-right: 60px;
            a {
                display: block;
                font-family: $boldM;
                font-size: 20px;
                color: #a5a9a9;
                position: relative;
                padding: 11px 0;
                &:after {
                    content: '';
                    width: 115px;
                    height: 2px;
                    background-color: #ff4d00;
                    position: absolute;
                    left: 0;
                    bottom: -1px;
                    opacity: 0;
                }
                &:hover {
                    color: #293333;
                }
            }
            &.active {
                a {
                    color: #293333;
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .btn {
        padding-left: 90px!important;
        padding-right: 90px!important;
    }
    @media screen and (max-width: 1200px) {
        ul li {
            a {
                font-size: 18px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        align-items: flex-end;
        ul {
            width: calc(100% - 235px);
            li {
                margin-right: 30px;
                a {
                    font-size: 16px;
                    &:after {
                        width: 75px;
                    }
                }
            }
        }
        .btn {
            padding-left: 40px!important;
            padding-right: 40px!important;
        }
    }
    @media screen and (max-width: 600px) {
        flex-wrap: wrap;
        margin-bottom: 15px;
        ul {
            width: 100%;
            li {
                margin-right: 15px;
                a {
                    font-size: 14px;
                    &:after {
                        width: 50px;
                    }
                }
            }
        }
        .btn {
            width: 100%!important;
            padding-left: 25px!important;
            padding-right: 25px!important;
            margin-top: 15px;
        }
    }
}
.tabs__block {
    display: none;
}
.tabs__table {
    background-color: rgba(255,255,255,0.9);
    margin-bottom: 5px;
    .tabs__title {
        padding: 10px 50px 10px 30px;
        border: 1px solid #fd804a;
        position: relative;
        cursor: pointer;
        transition: 300ms;
        user-select: none;
        &:before, &:after {
            content: '';
            width: 12px;
            height: 2px;
            background-color: #ff4d00;
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
            transition: 300ms;
        }
        &:after {
            transform: translateY(-50%) rotate(-90deg);
        }
        h5 {
            color: #293333;
            margin: 0;
            transition: 300ms;
        }
    }
    .tabs__down {
        display: none;
        border: 1px solid #fd804a;
        border-top: none;
    }
    &.open {
        .tabs__title {
            background-color: #ff4d00;
            &:before, &:after {
                background-color: #fff;
            }
            &:after {
                opacity: 0;
            }
            h5 {
                color: #fff;
            }
        }
    }
    table {
        width: 100%;
        border-collapse: collapse;
        tr {
            th {
                position: relative;
                font-family: $light;
                font-weight: normal;
                font-size: 12px;
                white-space: nowrap;
                color: #686f6f;
                text-align: left;
                padding: 20px 20px 6px;
                &:after {
                    content: '';
                    height: 1px;
                    background-color: #e7e8e8;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                &:first-of-type {
                    width: 475px;
                    &:after {
                        left: 30px;
                    }
                }
                &:last-of-type:after {
                    right: 30px;
                }
            }
            td {
                padding: 12px 20px;
                color: #293333;
                vertical-align: top;
                transition: 300ms;
                a {
                    color: #293333;
                    text-decoration: underline;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            &:last-of-type {
                td {
                    padding-bottom: 20px;
                }
            }
            &:hover {
                td {
                    background-color: #fd804a;
                    color: #fff;
                    a {
                        color: #fff;
                    }
                }
                .none {
                    background-color: #fff;
                }
            }
        }
    }
    .none {
        display: block;
        width: 16px;
        height: 1px;
        background-color: #5e6666;
        transition: 300ms;
    }
    // @media screen and (min-width: 1025px) {
    //     &:first-of-type {
    //         .tabs__title {
    //             background-color: #ff4d00;
    //             &:before, &:after {
    //                 background-color: #fff;
    //             }
    //             &:after {
    //                 opacity: 0;
    //             }
    //             h5 {
    //                 color: #fff;
    //             }
    //         }
    //         .tabs__down {
    //             display: block;
    //         }
    //         &.open {
    //             .tabs__title {
    //                 background-color: transparent;
    //                 &:before, &:after {
    //                     background-color: #ff4d00;
    //                 }
    //                 &:after {
    //                     opacity: 1;
    //                 }
    //                 h5 {
    //                     color: #293333;
    //                 }
    //             }
    //         }
    //     }
    // }
    @media screen and (max-width: 600px) {
        .tabs__title {
            padding: 10px 30px 10px 20px;
            &:after, &:before {
                right: 13px;
            }
        }
    }
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    bottom: auto;
    top: 0;
    left: 5px;
    right: 5px;
}
.mCSB_horizontal.mCSB_inside>.mCSB_container {
    margin: 15px 0 0;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #ff4d00!important;
    opacity: 1;
    border-radius: 0px;
}
.mCSB_scrollTools .mCSB_draggerRail,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    background-color: #293333;
    height: 4px;
    margin: 6px 0;
    border-radius: 0px;
}
.mCustomScrollbar,
.mCustomScrollbar.mCS_touch_action,
.mCustomScrollbar.mCS_touch_action .mCS-aviva.mCSB_scrollTools {
    -ms-touch-action: auto;
    touch-action: auto;
}
.mCustomScrollBox {
    -ms-touch-action: pan-x pinch-zoom;
    touch-action: pan-x pinch-zoom;
}
.mCustomScrollBox.mCSB_vertical_horizontal {
    -ms-touch-action: pinch-zoom;
    touch-action: pinch-zoom;
}
.mCustomScrollBox.mCSB_horizontal {
    -ms-touch-action: pan-y pinch-zoom;
    touch-action: pan-y pinch-zoom;
}
/* page schedule */

/* page catalog */
.page--cat {
    overflow: hidden;
    .bg--white {
        position: relative;
        &:before {
            content: '';
            background-color: #fff;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: calc(50% + 610px);
        }
    }
}
.cat--inside {
    display: flex;
    position: relative;
    z-index: 1;
    .left {
        width: 360px;
        position: relative;
        padding-right: 30px;
        &:before {
            content: '';
            height: 144px;
            background-color: #fff;
            position: absolute;
            top: -70px;
            left: -40px;
            right: 0;
            z-index: -1
        }
        .btn {
            width: calc(100%)!important;
            pointer-events: none;
        }
        ul {
            @include ul-default;
            padding: 30px 0;
            position: relative;
            &:after {
                content: '';
                background-color: #fff;
                position: absolute;
                left: -40px;
                right: -30px;
                top: calc(100% - 30px);
                height: 1000em;
            }
            li {
                a {
                    display: block;
                    font-family: $boldM; 
                    font-size: 18px;
                    padding: 10px 30px 10px 0;
                    position: relative;
                    &:before {
                        content: '';
                        background-color: #fff;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: -30px;
                        left: -40px;
                        z-index: -1;
                        transition: 300ms;
                    }
                    &:after {
                        content: '';
                        background: url(../img/bg-r.png) no-repeat top left;
                        background-size: contain;
                        height: 100%;
                        width: 39px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: -40px;
                    }
                    &:hover {
                        &:before {
                            opacity: 0;
                        }
                    }
                }
                &.active {
                    a {
                        color: #ff4d00;
                        &:before {
                            opacity: 0;
                        }
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .right {
        width: calc(100% - 360px);
        padding-left: 30px;
    }
    @media screen and (max-width: 1200px) {
        .left {
            &:before {
                height: 115px;
                top: -50px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .left {
            width: 280px;
            &:before {
                height: 95px;
                top: -30px;
            }
            ul li a {
                font-size: 16px;
                &:after {
                    display: none;
                }
            }
        }
        .right {
            width: calc(100% - 280px);
        }
    }
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        align-items: flex-start;
        .left {
            display: none;
            width: 100%;
            background-color: #fff;
            padding: 20px 15px 10px;
            margin-top: 0px;
            margin-bottom: 15px;
            &:before {
                display: none;
            }
            ul {
                padding: 15px 0 0;
                &:after {
                    display: none;
                }
                li {
                    a {
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
        .right {
            width: 100%;
            padding: 0;
        }
    }
}
.search {
    display: flex;
    overflow: hidden;
    margin-bottom: 30px;
    input[type="search"] {
        width: calc(100% - 82px);
        margin: 0!important;
        &::-webkit-search-cancel-button {
            appearance: none;
        }
    }
    button {
        width: 82px;
        background-color: #293333;
        border: none;
        padding: 0;
        position: relative;
        cursor: pointer;
        transition: 300ms;
        &:after {
            content: '';
            background: url(../img/search.png) no-repeat center center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &:hover {
            background-color: #ff4d00;
        }
    }
    @media screen and (max-width: 1024px) {
        input[type="search"] {
            width: calc(100% - 50px);
        }
        button {
            width: 50px;
            &:after {
                background-size: 22px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        margin-bottom: 20px;
    }

}
.cat--unit {
    display: flex;
    .left {
        width: 360px;
        padding-right: 30px;
        p {
            font-size: 14px;
        }
        h3 {
            margin: 0 0 10px;
        }
        .btn {
            width: 100%!important;
            margin: 0 0 15px;
        }
    }   
    .right {
        width: calc(100% - 360px);
        position: relative;
        padding: 0 0 0 30px;
        z-index: 1;
        &:before {
            content: '';
            width: 100vw;
            background-color: #fff;
            position: absolute;
            top: -70px;
            left: 0;
            bottom: -115px;
            z-index: -1;
        }
    }
    .cat--unit__title {
        display: block;
        font-family: $boldM;
        font-size: 16px;
        color: #293333;
        margin-bottom: 13px;
        span {
            color: #ff4d00;
        }
    }
    .cat--unit__up {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .cat--unit__item {
            .cat--unit__title {
                margin-bottom: 20px;
            }
        }
    }
    .tabs {
        ul {
            width: 100%;
        }
    }
    @media screen and (max-width: 1440px) {
        .right {
            &:before {
                bottom: -100px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .right {
            &:before {
                width: calc(100% + 15px);
                top: -50px;
                bottom: -75px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .left {
            width: 330px;
            padding-right: 20px;
        }
        .right {
            width: calc(100% - 330px);
            &:before {
                top: -30px;
                bottom: -50px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        .left {
            width: 100%;
            padding: 0;
            order: 2;
        }
        .right {
            width: 100%;
            padding: 0 0 15px;
            order: 1;
            margin-bottom: 15px;
            &:before {
                width: calc(100% + 30px);
                top: -20px;
                bottom: 0px;
                left: -15px;
            }
        }
        .cat--unit__up  {
            .cat--unit__item {
                .cat--unit__title {
                    margin-bottom: 10px;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        .cat--unit__title {
            font-size: 14px;
        }
        .cat--unit__up  {
            flex-wrap: wrap;
            .cat--unit__item {
                width: 50%;
                .cat--unit__title {
                    margin-bottom: 5px;
                }
            }
        }
    }
    @media screen and (max-width: 360px) {
        .cat--unit__up  {
            flex-wrap: wrap;
            .cat--unit__item {
                width: 100%;
            }
        }
    }
}
.city {
    margin: 25px 0 25px;
    .city__block {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
        .city__item {
            display: flex;
            height: 70px;
            width: calc(25% - 10px);
            background-color: rgba(255,255,255,0.9);
            font-family: $boldM;
            font-size: 12px;
            color: #293333;
            text-align: center;
            align-items: center;
            justify-content: center;
            padding: 3px 5px;
            border: 1px solid #ff4d00;
            line-height: 1;
            margin: 0 5px 10px;
            .date {
                span {
                    display: block;
                    font-size: 20px;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        margin: 15px 0;
    }
}
/* page catalog */

/* page contacts */
.cts {
    display: flex;
    margin-top: -70px;
    padding-bottom: 55px;
    .cts__city {
        width: 50%;
    }
    .cts__item {
        background-color: #fff;
        padding: 50px 50px 50px 75px;
        border: 1px solid transparent;
        cursor: pointer;
        transition: 300ms;
        position: relative;
        &:after {
            content: '';
            width: 16px;
            height: 26px;
            background: url(../img/tringl.png) no-repeat;
            position: absolute;
            left: -16px;
            top: 50%;
            opacity: 0;
            transition: 300ms;
            transform: translateY(-50%);
        }
        h3 {
            margin: 0 0 25px;
        }
        ul {
            @include ul-default;
            li {
                color: #666666;
                margin-bottom: 14px;
                a {
                    color: #666666;
                    &:hover {
                        color: #ff4d00;
                    }
                }
            }
        }
        &:hover {
            border: 1px solid #ff4d00;
        }
        &.active {
            border: 1px solid #ff4d00;
            &:after {
                opacity: 1;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .cts__item {
            padding: 30px 30px 30px 50px;
        }
    }
    @media screen and (max-width: 1200px) {
        padding-bottom: 35px;
        margin-top: -50px;
    }
    @media screen and (max-width: 1024px) {
        padding-bottom: 20px;
        padding-right: 0;
        margin-top: -30px;
        .cts__item {
            padding: 25px 15px 25px 30px;
            h3 {
                margin-bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        margin-top: -20px;
    }
    @media screen and (max-width: 600px) {
        flex-wrap: wrap;
        padding-bottom: 10px;
        .cts__item {
            width: 100%;
        }
    }
    @media screen and (max-width: 480px) {
        flex-wrap: wrap;
        padding: 15px 15px 25px;
        .map {
            width: 100%;
            height: 250px;
            order: 2;
        }
        .cts__city {
            width: 100%;
            flex-wrap: wrap;
        }
        .cts__item {
            width: 100%;
            padding: 20px 15px;
            &:after {
                display: none;
            }
        }
    }
}
.map {
    width: 50%;
    .map--block {
        display: none;
        height: 100%;
    }
    > ymaps, .ymaps-2-1-74-map {
        height: 100%!important;
    }
}
.form {
    h3 {
        text-transform: uppercase;
        margin: 0 0 20px;
    }
    p {
        color: #666666;
        margin-bottom: 18px;
    }
    .form__inp {
        display: flex;
        justify-content: space-between;
        input[type="text"],
        input[type="email"] {
            width: calc(33.33% - 10px);
        }
    }
    textarea {
        @include inline-block;
        min-height: 78px;
        width: calc(66.66% - 5px);
    }
    .g-recaptcha {
        @include inline-block;
        margin-left: 13px;
    }
    .btn {
        margin: 0 auto;
    }
    @media screen and (max-width: 1024px) {
        .g-recaptcha {
            float: right;
        }
    }
    @media screen and (max-width: 1024px) {
        textarea {
            width: 100%;
        }
        .g-recaptcha{ 
            float: none;
            margin-left: 0;
        }
        .btn {
            float: right;
            margin: 35px 0 0;
        }
    }
    @media screen and (max-width: 600px) {
        .form__inp {
            flex-wrap: wrap;
            input[type="text"],
            input[type="email"] { 
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 480px) {
        padding-bottom: 130px;
        .g-recaptcha {
            float: right;
        }
        .btn {
            margin-top: 12px;
        }
    }
    @media screen and (max-width: 340px) {
        .g-recaptcha {
            transform: scale(0.9);
            transform-origin: 100% 0;
        }
        .btn {
            margin-top: 10px;
        }
    }
}
/* page contacts */

/* modal */
.modal {
    display: none;
    background-color: rgba(0,0,0,0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    .modal__layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .modal__block {
        width: 420px;
        background-color: #fff;
        position: absolute;
        padding: 20px;
        left: 0;
        right: 0;
        top: 50%;
        margin: 0 auto;
        transform: translateY(-50%);
        h4 {
            font-size: 20px;
            text-align: center;
            padding: 0 10px;
            margin: 0;
        }
    }
    form {
        display: block;
        padding-bottom: 130px;
        margin-top: 15px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"],
        select {
            min-height: 44px;
            width: 100%;
            background-color: #fff;
            font-family: $regular;
            font-size: 16px;
            color: #293333;
            padding: 12px 20px;
            margin-bottom: 15px;
            border: 1px solid #d5d5d5;
            box-shadow: none;
            &::placeholder {
                color: #949999;
            }
        }
        .g-recaptcha {
            float: right;
        }
        .btn {
            float: right;
            margin: 15px 0 0 0;
        }
    }
    @media screen and (max-width: 1200px) {
        .modal__block {
            h4 {
                font-size: 18px;
            }
        }
        form {
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"],
            textarea {
                min-height: 40px;
                font-size: 14px;
                padding: 10px 15px;
                margin-bottom: 10px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        form {
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"],
            textarea {
                min-height: 35px;
                font-size: 14px;
                padding: 7px 15px;
                margin-bottom: 10px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .modal__block {
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        .modal__block {
            width: auto;
            left: 10px;
            right: 10px;
        }
    }
    @media screen and (max-width: 420px) {
        form {
            padding-bottom: 110px;
            .g-recaptcha {
                transform: scale(0.85);
                transform-origin: 100% 0%;
            }
            .btn {
                margin-top: 0;
            }
        }
    }
}

.call--btn {
    position: fixed;
    bottom: 100px;
    right: 40px;
    display: table;
    background-color: #ffcc00;
    font-family: 'FontAwesome';
    border-radius: 100%;
    text-decoration: none!important;
    width: 80px;
    height: 80px;
    text-align: center;
    padding: 23px 5px 5px 5px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.7);
    box-sizing: border-box;
    outline: none!important;
    z-index: 1;
    &:hover {
        animation-name: phone;
        animation-iteration-count: 1;
        animation-duration: 0.7s;
    }
    i {
        display: block;
        font-size: 35px;
        color: #fff;
    }
    &:after, &:before  {
        content: '';
        width: 90%;
        height: 90%;
        background-color: transparent;
        border: 1px solid #ffcc00;
        position: absolute;
        top: 5%;
        left: 5%;
        border-radius: 100%;
        animation-iteration-count: infinite;
        animation-duration: 1.2s;
        animation-name: ring;
        outline: none;  
    }
    &:after {
        animation-delay: 0.6s;
    }
    &:before {
        animation-delay: 0.8s;
        border: 1px solid #d9af04;
    }
    @media screen and (max-width: 1440px) {
        margin-right: 0;
        right: 35px;
        bottom: 75px;
    }
    @media screen and (max-width: 991px) {
        width: 50px;
        height: 50px;
        padding-top: 15px;
        right: 15px;
        bottom: 80px;
        i {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 768px) {
        bottom: 50px;
    }
}

@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.1;
    transform: scale(1.7);
  }
}
@keyframes phone {
  20% {
   transform: rotate(-30deg); 
  }

  80% {
    transform: rotate(390deg)
  }

  100% {
    transform: rotate(360deg);
  }
}

/* modal */

/* page content */