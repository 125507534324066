/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 29, 2019 */
@font-face {
    font-family: 'ubuntubold';
    src: url('../fonts/ubuntu-b-webfont.eot');
    src: url('../fonts/ubuntu-b-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ubuntu-b-webfont.woff2') format('woff2'),
         url('../fonts/ubuntu-b-webfont.woff') format('woff'),
         url('../fonts/ubuntu-b-webfont.ttf') format('truetype'),
         url('../fonts/ubuntu-b-webfont.svg#ubuntubold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ubuntulight';
    src: url('../fonts/ubuntu-l-webfont.eot');
    src: url('../fonts/ubuntu-l-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ubuntu-l-webfont.woff2') format('woff2'),
         url('../fonts/ubuntu-l-webfont.woff') format('woff'),
         url('../fonts/ubuntu-l-webfont.ttf') format('truetype'),
         url('../fonts/ubuntu-l-webfont.svg#ubuntulight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ubuntumedium';
    src: url('../fonts/ubuntu-m-webfont.eot');
    src: url('../fonts/ubuntu-m-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ubuntu-m-webfont.woff2') format('woff2'),
         url('../fonts/ubuntu-m-webfont.woff') format('woff'),
         url('../fonts/ubuntu-m-webfont.ttf') format('truetype'),
         url('../fonts/ubuntu-m-webfont.svg#ubuntumedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ubunturegular';
    src: url('../fonts/ubuntu-r-webfont.eot');
    src: url('../fonts/ubuntu-r-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ubuntu-r-webfont.woff2') format('woff2'),
         url('../fonts/ubuntu-r-webfont.woff') format('woff'),
         url('../fonts/ubuntu-r-webfont.ttf') format('truetype'),
         url('../fonts/ubuntu-r-webfont.svg#ubunturegular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'magistralcbold';
    src: url('../fonts/magistralc-bold-webfont.eot');
    src: url('../fonts/magistralc-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/magistralc-bold-webfont.woff2') format('woff2'),
         url('../fonts/magistralc-bold-webfont.woff') format('woff'),
         url('../fonts/magistralc-bold-webfont.ttf') format('truetype'),
         url('../fonts/magistralc-bold-webfont.svg#magistralcbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

$regular: 'ubunturegular';
$bold: 'ubuntubold';
$boldM: 'magistralcbold';
$medium: 'ubuntumedium';
$light: 'ubuntulight';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

[data-src] {
	opacity: 0;
	&[src] {
		opacity: 1;
		transition-duration: 400ms;
		transition-delay: 0.2s;
	}
}

.preload {
	background-color: #293333;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	pointer-events: none;
	&.load {
		opacity: 0;
		transition: 500ms;
	}
	&.loadR {
		opacity: 1;
		transition: 500ms;
	}
	.sk-chasing-dots {
        width: 4em;
        height: 4em;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        margin-top: -2rem;
        animation: sk-chasing-dots-rotate 2s infinite linear;
        .sk-child {
            width: 2em;
            height: 2em;
            display: inline-block;
            position: absolute;
            top: 0;
            background-color: #fefefe;
            border-radius: 100%;
            animation: sk-chasing-dots-bounce 2s infinite ease-in-out;
        }
        .sk-dot-2 {
            top: auto;
            bottom: 0;
            animation-delay: -1s;
        }
    }
}
@-webkit-keyframes sk-chasing-dots-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-chasing-dots-rotate {
    100% {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes sk-chasing-dots-bounce {
    0%, 100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}
@keyframes sk-chasing-dots-bounce {
    0%, 100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}

body {
	display: flex;
	height: 100vh;
    min-width: 300px;
	position: relative;
	flex-direction: column;
	font-family: $regular;
	-webkit-text-size-adjust: 100%;
    color: #293333;
    padding-top: 110px;
	margin: 0;
    overflow-x: hidden;
    @media screen and (max-width: 768px) {
        display: block;
        padding-top: 80px;
    }
}

h1 {
	font-family: $boldM;
	font-size: 38px;
	text-transform: uppercase;
	color: #293333;
	line-height: 1;
	margin: 0 0 35px;
    @media screen and (max-width: 1440px) {
        font-size: 36px;
        margin-bottom: 25px;
    }
	@media screen and (max-width: 1200px) {
		font-size: 32px;
	}
	@media screen and (max-width: 1024px) {
		font-size: 28px;
        margin-bottom: 20px;
	}
    @media screen and (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 15px;
    }
}
h2 {
	font-family: $boldM;
	font-size: 36px;
	color: #293333;
    margin: 0 0 35px;
    line-height: 1;
    @media screen and (max-width: 1440px) {
        font-size: 25px;
    }
	@media screen and (max-width: 1200px) {
		font-size: 28px;
        margin-bottom: 20px;
	}
	@media screen and (max-width: 1024px) {
		font-size: 24px;
	}
    @media screen and (max-width: 768px) {
        font-size: 22px;
        margin-bottom: 15px;
    }
}
h3 {
	font-family: $boldM;
	font-size: 24px;
	color: #293333;
    line-height: 1.2;
	@media screen and (max-width: 1440px) {
		font-size: 22px;
	}
    @media screen and (max-width: 1366px) {
        font-size: 20px;
    }
    @media screen and (max-width: 1200px) {
        font-size: 18px;
    }
}
h4 {
	font-family: $boldM;
	font-size: 22px;
	color: #293333;
    @media screen and (max-width: 1440px) {
        font-size: 20px;
    }
    @media screen and (max-width: 1200px) {
        font-size: 18px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 16px;
    }
}
h5 {
	font-family: $boldM;
	font-size: 18px;
	color: #293333;
    @media screen and (max-width: 1440px) {
        font-size: 16px;
    }
}
h6 {
	font-family: $boldM;
	font-size: 16px;
	text-transform: uppercase;
	color: #293333;
    @media screen and (max-width: 1024px) {
        font-size: 14px;
    }
}
p {
    margin: 0 0 15px;
}
a {
    color: #293333;
	transition: 300ms;
	text-decoration: none;
	&:focus {
		color: #293333;
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
        color: #ff4d00;
	}
}
textarea, 
button, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
    font-size: 16px;
    line-height: 1.4;
	textarea, 
    input[type="text"], 
    input[type="email"], 
    input[type="search"], 
    input[type="password"] {
        min-height: 44px;
        width: 100%;
		background-color: #fff;
		font-family: $regular;
		font-size: 16px;
        color: #293333;
		padding: 12px 20px;
        margin-bottom: 15px;
        border: 1px solid #d5d5d5;
        box-shadow: none;
        &::placeholder {
            color: #949999;
        }
	}
    @media screen and (max-width: 1440px) {
        font-size: 14px;
    }
    @media screen and (max-width: 1200px) {
        line-height: 1.2;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            min-height: 40px;
            font-size: 14px;
            padding: 10px 15px;
        }
    }
}
.main {
	.container {
		padding-top: 115px;
		padding-bottom: 115px;
		@media screen and (max-width: 1440px) {
			padding-top: 90px;
			padding-bottom: 90px;
		}
        @media screen and (max-width: 1366px) {
            padding-top: 70px;
            padding-bottom: 70px;
        }
        @media screen and (max-width: 1200px) {
            padding-top: 50px;
            padding-bottom: 50px;
        }
		@media screen and (max-width: 1024px) {
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}
    h1 {
        font-size: 36px;
    }
    @media screen and (max-width: 1440px) {
        h1 {
            font-size: 30px;
        }
    }
    @media screen and (max-width: 1200px) {
        h1 {
            font-size: 28px;
        }
    }
    @media screen and (max-width: 1024px) {
        h1 {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 768px) {
        h1 {
            font-size: 22px;
        }
    }
}

.container {
    width: 1170px;
    padding: 0 15px;
    margin: 0 auto;
    @media screen and (max-width: 1200px) {
        width: auto;
    }
}

.close {
    width: 22px;
    height: 22px;
    position: absolute;
    right: 8px;
    top: 10px;
    opacity: 1;
    outline: none;
    &:before, &:after {
        content: '';
        width: 22px;
        height: 2px;
        background-color: #ff4d00;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
}
.btn {
	display: table;
    height: 44px;
	width: auto!important;
	background-color: #ff4d00!important;
	font-family: $boldM!important;
	font-size: 16px;
	color: #fff!important;
    text-transform: uppercase;
    text-align: center;
	cursor: pointer;
	border-radius: 0px;
	border: 2px solid #ff4d00;
	padding: 12px 40px 12px!important;
    line-height: 1;
	transition: 300ms;
	&:focus, &:active {
		background-color: #ff4d00!important;
		color: #fff;
	}
	&:hover, &.active {
		background-color: #ff4d00!important;
		color: #fff!important;
        opacity: 0.6;
	}
    @media screen and (max-width: 1200px) {
        height: 40px;
        font-size: 14px;
        padding: 10px 25px 10px!important;
    }	
}
input.btn {
    padding: 12px 40px 12px!important;
    @media screen and (max-width: 1200px) {
        height: 40px;
        font-size: 14px;
        padding: 10px 25px 10px!important;
    }   
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		padding-left: 25px;
		margin-bottom: 5px;
		&:before {
			content: '\f00c';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #ff4d00;
			left: 0;
			top: 0px;
		}
	}
}

.arr--list {
    @include ul-default;
    li {
        position: relative;
        padding-left: 16px;
        margin: 13px 0;
        &:after {
            content: '\f105';
            font-family: 'fontAwesome';
            font-size: 18px;
            color: #ff4d00;
            position: absolute;
            top: 1px;
            left: 0;
        }
    }
    @media screen and (max-width: 1200px) {
        li {
            &:after {
                font-size: 16px;
                top: 0px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        li {
            margin: 10px 0;
        }
    }
}

.col--location,
.col--phone,
.col--email,
.col--mob {
	position: relative;
	padding-left: 25px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 15px;
		color: #ff4d00;
		position: absolute;
		top: 4px;
		left: 0;
	}
    @media screen and (max-width: 1440px) {
        &:after {
            top: 2px;
        }
    }
    @media screen and (max-width: 1024px) {
        padding-left: 20px;
    }
}
.col--location:after {
	content: '\f041';
}
.col--phone {
    &:after {
        content: '\f095';
    }
    a {
        display: table;
        pointer-events: none;
    }
    @media screen and (max-width: 1440px) {
        &:after {
            top: 3px;
        }
    }
    @media screen and (max-width: 1024px) {
        a {
            pointer-events: auto;
        }
    }
}
.col--email {
    &:after {
        content: '\f0e0';
        font-size: 13px;
        top: 5px;
    }
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    @media screen and (max-width: 1440px) {
        &:after {
            top: 3px;
        }
    }
    @media screen and (max-width: 1024px) {
        a {
            pointer-events: auto;
        }
    }
}
.col--mob {
    &:after {
        content: '\f10b';
        font-size: 20px;
        top: 0px;
    }
    @media screen and (max-width: 1024px) {
        &:after {
            top: -1px;
        }
    }
}

.bg {
    background-color: #293333;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    #particles-js {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.figure {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 15px 0;
    overflow: hidden;
    z-index: 1;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
    &.left {
        padding-left: 85px;
        padding-right: 40px;
        &:before {
            right: -100px;
            left: 35px;
            border-radius: 10px 0 0 0;
            transform: skew(-35deg);
        }
    }
    &.right {
        padding-right: 85px;
        padding-left: 40px;
        &:before {
            left: -100px;
            right: 35px;
            border-radius: 0 10px 0 0;
            transform: skew(35deg);
        }
    }
    &.orange {
        color: #fff;
        &:before {
            background-color: #ff4d00;
        }
    }
    &.light {
        &:before {
            background-color: #f7f7f7;
        }
    }
    &.white {
        &:before {
            background-color: #ffff;
        }
    }
    &.gray {
        color: #fff;
        &:before {
            background-color: #293333;
        }
    }
    @media screen and (max-width: 1440px) {
        padding: 12px 0;
        &.right {
            padding-left: 25px;
            padding-right: 50px;
            &:before {
                right: 15px;
            }
        }
        &.left {
            padding-left: 50px;
            padding-right: 25px;
            &:before {
                left: 15px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 10px 0;
        &.right {
            padding-left: 15px;
            padding-right: 35px;
        }
        &.left {
            padding-left: 35px;
            padding-right: 15px;
        }
    }
}

.bg--white {
    background: #ffffff url(../img/img-4.png);
}
.bg--gray {
    background-color: #f7f7f7;
}

/* header */
header {
    background-color: #fff;
    font-size: 14px;
    padding-bottom: 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    box-shadow: 0px 0px 15px #293333;
    &:before {
        content: '';
        height: 33px;
        background-color: #ff4d00;
        position: absolute;
        top: 41px;
        left: -100px;
        right: calc(50% + 550px);
        border-radius: 0 0 10px 0;
        transform: skew(-35deg);
    }
    ul {
        @include ul-default;
    }

	/* header logo */
	.logo {
        width: 140px;
        display: block;
        position: absolute;
        left: 75px;
        top: 17px;
        img {
            display: block;
            max-width: 100%;
        }
    }
	/* header logo */

	/* header nav */
	.nav {
        width: calc(100% + 85px);
        height: 50px;
        padding-right: 0;
        margin-left: -85px;
        overflow: inherit;
        &.left {
            padding-right: 0;
        }
        &:before {
            width: 100vw;
            right: auto;
        }
        > ul {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            > li {
                > a {
                    font-weight: normal;
                    font-family: $boldM;
                    font-size: 16px;
                    color: #fff;
                    &:hover {
                        color: #ff4d00;
                    }
                }
                &.active > a {
                    color: #ff4d00;
                }
                ul {
                    display: none;
                }
            }
        }
    }
	/* header nav */

    .h--cts {
        display: flex;
        height: 40px;
        align-items: center;
        justify-content: space-between;
        font-family: $light;
        position: relative;
        z-index: 2;
        .close {
            display: none;
        }
        p {
            margin-bottom: 0;
        }
        .col--phone {
            position: relative;
            padding-right: 15px;
            .h--arr {
                height: 8px;
                font-family: 'fontAwesome';
                font-size: 14px;
                color: #333333;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                line-height: 1;
                pointer-events: auto;
                z-index: 1;
                &:after {
                    content: '\f107';
                }
            }
            ul {
                display: none;
                background-color: rgba(41, 51, 51, 0.8);
                padding: 5px 10px;
                position: absolute;
                left: 10px;
                top: 100%;
                box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
                z-index: 1;
                li {
                    margin: 5px 0;
                    a {
                        white-space: nowrap;
                        color: #fff;
                    }
                }
            }
            &.open {
                .h--arr {
                    transform: rotate(-180deg) translateY(50%);
                }
            }
        }
    }

    .h--cts--btn {
        display: none;
        font-family: 'fontAwesome';
        font-size: 20px;
        color: #ff4d00;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        &:after {
            content: '\f095';
        }
        &:hover {
            text-decoration: none;
        }
    }
    
    .col--location, 
    .col--phone {
        padding-left: 21px;
        &:after {
            font-size: 14px;
            top: 1px;
        }
    }
    .col--email {
        padding-left: 21px;
        &:after {
            font-size: 12px;
            top: 3px;
        }
    }

    .container {
        position: relative;
        padding-left: 300px;
    }
	
	@media screen and (max-width: 1024px) {
        .container {
            padding-left: 250px;
        }
        .logo {
            left: 15px;
        }
	}
    @media screen and (max-width: 768px) {
        background-color: #fff;
        padding: 0;
        .container {
            height: 80px;
            padding-left: 15px;
        }
        .logo {
            width: 110px;
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            margin: 0 auto;
            transform: translateY(-50%);
            img {
                width: 100%;
            }
        }
        .nav {
            display: none;
            background-color: #293333;
            height: auto;
            max-height: calc(100% - 80px);
            width: 100%;
            position: fixed;
            top: 80px;
            left: 0;
            right: 0;
            overflow: auto;
            padding: 0px!important;
            margin: 0;
            box-shadow: 0px 4px 7px rgba(255, 77, 0, 0.7);
            z-index: 1;
            &.figure {
                &:before {
                    display: none;
                }
            }
            > ul {
                flex-direction: column;
                padding: 15px;
                > li {
                    text-align: center;
                    margin: 5px 0;
                    ul {
                        padding: 10px 0;
                        li {
                            margin: 7px 0;
                            a {
                                font-size: 14px;
                                color: #fff;
                                &:hover {
                                    color: #ff4d00;
                                }
                            }
                            &.active a {
                                color: #ff4d00;
                            }
                        }
                    }
                }
            }
        }
        .nav--btn {
            width: 35px;
            height: 30px;
            position: absolute;
            left: 10px;
            top: 50%;
            padding: 0;
            margin: 0;
            outline: none;
            float: none;
            transform: translateY(-50%);
            span {
                position: absolute;
                left: 0;
                right: 0;
                width: 85%;
                height: 3px;
                margin: 0 auto;
                background-color: #ff4d00;
                transition: 350ms;
                &:nth-of-type(1) {
                    top: 7px;
                }
                &:nth-of-type(2) {
                    top: 14px;
                }
                &:nth-of-type(3) {
                    top: 21px;
                }
            }
            &.open {
                span {
                    &:nth-of-type(1) {
                        top: 14px;
                        transform: rotate(45deg);
                    }
                    &:nth-of-type(2) {
                        opacity: 0;
                    }
                    &:nth-of-type(3) {
                        top: 14px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
        .h--cts {
            display: none;
            height: auto;
            background-color: #fff; 
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            padding: 10px 25px 15px;
            box-shadow: 0px 0px 10px #293333;
            .h--cts__item {
                display: table;
                margin: 15px auto;
            }
            .col--location {
                text-align: center;
            }
            .col--phone {
                margin-bottom: 0;
                .h--arr {
                    display: none;
                }
                ul {
                    display: block;
                    background-color: transparent;
                    position: relative;
                    top: 0;
                    left: 0;
                    box-shadow: none;
                    padding: 0;
                    li {
                        margin: 7px 0;
                        a {
                            color: #293333;
                            &:hover {
                                color: #ff4d00;
                            }
                        }
                    }
                }
            }
            .close {
                display: block;
                right: 5px;
                top: 5px;
            }
        }
        .h--cts--btn {
            display: block;
        }
    }
    @media screen and (max-width: 420px) {
        .h--cts {
            .col--location {
                width: 240px;
            }
        }
    }
}
/* header */