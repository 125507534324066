/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
	margin-bottom: 0px!important;
	.slick-slide {
        height: 600px;
		overflow: hidden;
		position: relative;
		outline: none;
		.slider__block {
            width: 1024px;
			position: absolute;
			left: 50%;
			top: 50%;
            font-size: 18px;
			color: #ffffff;
            margin-left: -570px;
            transform: translateY(-75%);
            .slider__block--title {
                display: block;
                font-family: $boldM;
                font-size: 60px;
                line-height: 1.2;
                margin-bottom: 20px;
                span {
                    display: block;
                    font-size: 36px;
                }
            }
            .btn {
                margin-top: 40px;
            }
		}
	}
	.slick-dots {
        width: auto;
        right: auto;
        left: 50%;
		bottom: 100px;
        margin-left: -570px;
        line-height: 1;
		li {
            width: auto;
            height: auto;
			margin: 0 18px 0 0;
			span {
                display: block;
				width: auto;
				height: auto;
                font-family: $boldM;
                font-size: 24px;
                color: #949999;
                padding-bottom: 8px;
                border-bottom: 2px solid transparent;
                transition: 300ms;
				&:before {
					display: none;
				}
				&:hover {
					color: #ff4d00;
                    border-bottom: 2px solid #ff4d00;
				}
			}
			&.slick-active {
				span {
					color: #ff4d00;
				    border-bottom: 2px solid #ff4d00;
                }
			}
		}
	}
    @media screen and (max-width: 1440px) {
        .slick-slide {
            height: 450px;
            .slider__block {
                font-size: 16px;
                .slider__block--title {
                    font-size: 48px;
                    span {
                        font-size: 28px;
                    }
                }
            }
        }
        .slick-dots {
            bottom: 50px;
        }
    }
    @media screen and (max-width: 1366px) {
        .slick-slide {
            height: 400px;
            .slider__block {
                .slider__block--title {
                    font-size: 38px;
                    span {
                        font-size: 24px;
                    }
                }
            }
        }
        .slick-dots {
            bottom: 30px;
        }
    }
    @media screen and (max-width: 1200px) {
        .slick-slide {
            .slider__block {
                width: auto;
                left: 20px;
                right: 20px;
                margin: 0;
                .slider__block--title {
                    font-size: 30px;
                    span {
                        font-size: 20px;
                    }
                }
                .btn {
                    margin-top: 30px;
                }
            }
        }
        .slick-dots {
            left: 20px;
            margin: 0;
            li {
                span {
                    font-size: 20px;
                    padding-bottom: 5px;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .slick-slide {
            height: auto;
            .slider__block {
                position: relative;
                left: 0;
                right: 0;
                top: 0;
                transform: translateY(0);
                padding: 30px 20px 75px;
            }
        }
        .slick-dots {
            bottom: 20px;
        }
    }
    @media screen and (max-width: 768px) {
        .slick-slide {
            .slider__block {
                .slider__block--title {
                    font-size: 24px;
                    span {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        .slick-slide {
            .slider__block {
                font-size: 14px;
                .slider__block--title {
                    font-size: 22px;
                    margin-bottom: 15px;
                    span {
                        font-size: 17px;
                    }
                }
                .btn {
                    margin-top: 20px;
                }
            }
        }
    }
}
/* main slider */

/* main package */
.package {
    .container {
        position: relative;
    }
    .more {
        position: absolute;
        right: 10px;
        top: 128px;
    }
    @media screen and (max-width: 1440px) {
        .more {
            top: 95px;
        }
    }
    @media screen and (max-width: 1366px) {
        .more {
            top: 76px;
        }
    }
    @media screen and (max-width: 1200px) {
        .more {
            top: 60px;
        }
    }
    @media screen and (max-width: 1024px) {
        .more {
            top: 37px;
        }
    }
    @media screen and (max-width: 768px) {
        .more {
            top: 35px;
        }
    }
    @media screen and (max-width: 600px) {
        .more {
            position: relative;
            top: 0;
            right: auto;
            left: 0;
            margin-bottom: 15px;
        }
    }
}
.package__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .package__item {
        width: calc(50% - 15px);
        transition: 0ms;
        position: relative;
        padding-top: 48px;
        margin-bottom: 20px;
        &:nth-last-of-type(-n+2) {
            margin-bottom: 0;
        }
        .date {
            font-family: $light;
            position: absolute;
            top: 0px;
            left: 0;
            line-height: 1.2;
        }
        .package__info {
            width: 100%;
            height: 100%;
            background-color: rgba(255,255,255,0.9);
            border: 1px solid #ff4d00;
            padding: 30px 30px;
            transition: 300ms;
            h3 {
                font-size: 20px;
                margin: 0;
                transition: 300ms;
            }
            p {
                margin: 15px 0 0 0;
            }
        }
        &:hover {
            color: #fff;
            .package__info {
                background-color: #293333;
                h3 {
                    color: #fff;
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .package__item {
            padding-top: 40px;
            .package__info {
                padding: 30px 20px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        margin: 0 -10px;
        .package__item {
            width: calc(50% - 20px);
            padding-top: 35px;
            margin: 0 10px 20px;
            .package__info {
                padding: 20px 15px;
                h3 {
                    font-size: 18px;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        margin: 0px;
        .package__item {
            width: 100%;
            margin: 0 0 15px;
            &:nth-last-of-type(2n) {
                margin-bottom: 15px;
            }
            .package__info {
                h3 {
                    font-size: 16px;
                }
            }
        }
    }
}
.more {
    display: table;
    font-family: $boldM;
    text-transform: uppercase;
    color: #ff4d00;
    padding-right: 20px;
    position: relative;
    line-height: 1.1;
    &:before, &:after {
        content: '\f105';
        font-family: 'fontAwesome';
        font-size: 16px;
        color: #ff4d00;
        position: absolute;
        right: 0;
        top: 0;
        transition: 300ms;
    }
    &:after {
        right: 7px;
    }
    &:hover {
        &:after {
            right: 2px;
        }
        &:before {
            right: -5px;
        }
    }
    @media screen and (max-width: 1440px) {
        &:before, &:after {
            top: -2px;
        }
    }
    @media screen and (max-width: 768px) {
        &:before, &:after {
            top: 0px;
        }
    }
}
/* main package */

/* main cat */
.cat {
    .container {
        padding-top: 0;
    }
}
.cat__block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    .cat__item {
        height: 265px;
        width: calc(25% - 30px);
        background-color: rgba(255,255,255,0.9);
        text-align: center;
        margin: 0 15px 30px;
        position: relative;
        &:nth-last-child(-n+4) {
            margin-bottom: 0;
        }
        .decor {
            border: 1px solid #ff4d00;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: 300ms;
            &:before, &:after {
                content: '';
                width: 0;
                height: 0;
                border-top: 10px solid #ff4d00;
                border-left: 9px solid transparent;
                border-right: 9px solid transparent;
                position: absolute;
                left: 50px;
                top: 0;
                transition: 300ms;
            }
            &:after {
                top: auto;
                left: auto;
                bottom: 0;
                right: 50px;
                border-bottom: 10px solid #ff4d00;
                border-top: none;
            }
        }
        .cat__txt {
            position: absolute;
            top: 50%;
            left: 30px;
            right: 30px;
            transform: translateY(-50%);
        }
        h4 {
            transition: 300ms;
            margin: 0;
        }
        .cat--more {
            display: block;
            width: 35px;
            height: 30px;
            font-family: 'fontAwesome';
            font-size: 36px;
            color: #fff;
            margin: 10px auto 0;
            position: relative;
            &:before, &:after {
                content: '\f105';
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
            }
            &:after {
                right: 5px;
            }
        }
        &.last {
            background-color: #293333;
            .decor {
                top: 18px;
                right: 18px;
                bottom: 18px;
                left: 18px;
            }
            h4 {
                color: #fff;
            }
        }
        &:hover {
            background-color: #ff4d00;
            .decor {
                top: 18px;
                bottom: 18px;
                left: 18px;
                right: 18px;
                border: 1px solid #fff;
                &:before {
                    border-top: 10px solid #fff;
                }
                &:after {
                    border-bottom: 10px solid #fff;
                }
            }
            h4 {
                color: #fff;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        margin: 0 -10px;
        .cat__item {
            width: calc(25% - 20px);
            height: 215px;
            margin: 0 10px 20px;
            .cat--more {
                height: 18px;
                font-size: 24px;
                margin-top: 5px;
                &:after {
                    left: 15px;
                }
                &:before {
                    left: 10px;
                }
            }
            &.last {
                .decor {
                    top: 10px;
                    right: 10px;
                    bottom: 10px;
                    left: 10px;
                }
            }
            &:hover {
                .decor {
                    top: 10px;
                    right: 10px;
                    left: 10px;
                    bottom: 10px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .cat__item {
            width: calc(33.33% - 20px);
            .decor {
                &:before {
                    left: 20px;
                }
                &:after {
                    right: 20px;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        .cat__item {
            width: calc(50% - 20px);
            &:nth-last-child(3n),
            &:nth-last-of-type(4n) {
                margin-bottom: 20px;
            }
        }
    }
    @media screen and (max-width: 420px) {
        .cat__item {
            height: 180px;
            .cat__txt {
                left: 20px;
                right: 20px;
            }
        }        
    }
    @media screen and (max-width: 340px) {
        .cat__item {
            height: 150px;
            h4 {
                font-size: 14px;
            }
        }        
    }
}
/* main cat */

/* main about */
.about {
    overflow: hidden;
    .container {
        padding-top: 70px;
        padding-bottom: 0;
        &.figure {
            display: block;
            overflow: visible;
            padding-left: 15px;
            padding-right: 15px;
            &.left:before {
                width: 120vw;
                left: -235px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .container {
            &.figure {
                &.left:before {
                    width: 160vw;
                    left: -200px;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .container {
            padding-top: 50px;
            &.figure {
                &.left:before {
                    width: 160vw;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .container {
            padding-top: 30px;
            &.figure {
                background-color: #f7f7f7;
                &:before {
                    display: none;
                }
            }
        }
    }
}
.about__block {
    display: flex;
    justify-content: space-between;
    .left {
        width: calc(100% - 470px);
        padding-right: 100px;
    }
    .right {
        max-width: 470px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {
            display: block;
            max-width: 100%;
        }
    }
    @media screen and (max-width: 1440px) {
        .left {
            width: calc(100% - 300px);
        }
        .right {
            max-width: 300px;
        }
    }
    @media screen and (max-width: 1024px) {
        .left {
            padding-right: 50px;
        }
    }
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        .left {
            width: 100%;
            padding-right: 0;
        }
        .right {
            width: 100%;
            max-width: 100%;
            margin: 10px 0;
            img {
                display: table;
                margin: 0 auto;
            }
        }
    }
}
.about__slogan  {
    font-style: italic;
    padding-top: 23px;
    padding-bottom: 23px;
    line-height: 1.4;
    margin-top: 45px;
    &.figure {
        overflow: visible;
        &.left {
            &:before {
                border-radius: 25px 0 0 0;
            }
        }
        &:before {
            width: 100vw;
        }
    }
    * {
        margin: 0;
    }
    @media screen and (max-width: 1440px) {
        &.figure {
            &.left {
                padding-left: 65px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        margin-top: 35px;
    }
    @media screen and (max-width: 1024px) {
        margin-top: 20px;
    }
    @media screen and (max-width: 768px) {
        &.figure {
            background-color: #293333;
            padding: 20px!important;
            &.left:before {
                display: none;
            }
        }
    }
}
/* main about */

/* main news */
.news__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .news__item {
        width: calc(50% - 15px);
        color: #293333;
        margin-bottom: 30px;
        &:nth-last-child(-n+2) {
            margin-bottom: 0;
        }
        .news__img {
            height: 312px;
            overflow: hidden;
            position: relative;
            margin-bottom: 35px;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                transition: 300ms;
            }
            .date {
                font-family: $light;
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }
        h3 {
            margin: 0 0 15px 0;
            transition: 300ms;
        }
        p {
            margin-bottom: 0;
        }
        &:hover {
            .news__img {
                img {
                    transform: scale(1.1);
                }
            }
            h3 {
                color: #ff4d00;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .news__item {
            .news__img {
                height: 300px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .news__item {
            .news__img {
                height: 255px;
                margin-bottom: 20px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .news__item {
            .news__img {
                height: 180px;
                margin-bottom: 10px;
            }
            h3 {
                margin-bottom: 10px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .news__item {
            width: 100%;
            margin-bottom: 20px;
            &:nth-last-child(-n+2) {
                margin-bottom: 20px;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
            .news__img {
                height: auto;
                img {
                    height: auto;
                }
            }
        }
    }
}
/* main news */

/* main content */