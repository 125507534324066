/* Connection Font Icons */
@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome-webfont.eot");
  src: url("../fonts/fontawesome-webfont.eot") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2") format("woff2"), url("../fonts/fontawesome-webfont.woff") format("woff"), url("../fonts/fontawesome-webfont.ttf") format("truetype"), url("../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: 400;
  font-style: normal; }

/* Connection Font Icons */
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 29, 2019 */
@font-face {
  font-family: 'ubuntubold';
  src: url("../fonts/ubuntu-b-webfont.eot");
  src: url("../fonts/ubuntu-b-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/ubuntu-b-webfont.woff2") format("woff2"), url("../fonts/ubuntu-b-webfont.woff") format("woff"), url("../fonts/ubuntu-b-webfont.ttf") format("truetype"), url("../fonts/ubuntu-b-webfont.svg#ubuntubold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'ubuntulight';
  src: url("../fonts/ubuntu-l-webfont.eot");
  src: url("../fonts/ubuntu-l-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/ubuntu-l-webfont.woff2") format("woff2"), url("../fonts/ubuntu-l-webfont.woff") format("woff"), url("../fonts/ubuntu-l-webfont.ttf") format("truetype"), url("../fonts/ubuntu-l-webfont.svg#ubuntulight") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'ubuntumedium';
  src: url("../fonts/ubuntu-m-webfont.eot");
  src: url("../fonts/ubuntu-m-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/ubuntu-m-webfont.woff2") format("woff2"), url("../fonts/ubuntu-m-webfont.woff") format("woff"), url("../fonts/ubuntu-m-webfont.ttf") format("truetype"), url("../fonts/ubuntu-m-webfont.svg#ubuntumedium") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'ubunturegular';
  src: url("../fonts/ubuntu-r-webfont.eot");
  src: url("../fonts/ubuntu-r-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/ubuntu-r-webfont.woff2") format("woff2"), url("../fonts/ubuntu-r-webfont.woff") format("woff"), url("../fonts/ubuntu-r-webfont.ttf") format("truetype"), url("../fonts/ubuntu-r-webfont.svg#ubunturegular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'magistralcbold';
  src: url("../fonts/magistralc-bold-webfont.eot");
  src: url("../fonts/magistralc-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/magistralc-bold-webfont.woff2") format("woff2"), url("../fonts/magistralc-bold-webfont.woff") format("woff"), url("../fonts/magistralc-bold-webfont.ttf") format("truetype"), url("../fonts/magistralc-bold-webfont.svg#magistralcbold") format("svg");
  font-weight: normal;
  font-style: normal; }

* {
  box-sizing: border-box; }

*:after, *:before {
  display: block;
  line-height: 1; }

[data-src] {
  opacity: 0; }
  [data-src][src] {
    opacity: 1;
    transition-duration: 400ms;
    transition-delay: 0.2s; }

.preload {
  background-color: #293333;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  pointer-events: none; }
  .preload.load {
    opacity: 0;
    transition: 500ms; }
  .preload.loadR {
    opacity: 1;
    transition: 500ms; }
  .preload .sk-chasing-dots {
    width: 4em;
    height: 4em;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    margin-top: -2rem;
    animation: sk-chasing-dots-rotate 2s infinite linear; }
    .preload .sk-chasing-dots .sk-child {
      width: 2em;
      height: 2em;
      display: inline-block;
      position: absolute;
      top: 0;
      background-color: #fefefe;
      border-radius: 100%;
      animation: sk-chasing-dots-bounce 2s infinite ease-in-out; }
    .preload .sk-chasing-dots .sk-dot-2 {
      top: auto;
      bottom: 0;
      animation-delay: -1s; }

@-webkit-keyframes sk-chasing-dots-rotate {
  100% {
    transform: rotate(360deg); } }

@keyframes sk-chasing-dots-rotate {
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes sk-chasing-dots-bounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

@keyframes sk-chasing-dots-bounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

body {
  display: flex;
  height: 100vh;
  min-width: 300px;
  position: relative;
  flex-direction: column;
  font-family: "ubunturegular";
  -webkit-text-size-adjust: 100%;
  color: #293333;
  padding-top: 110px;
  margin: 0;
  overflow-x: hidden; }
  @media screen and (max-width: 768px) {
    body {
      display: block;
      padding-top: 80px; } }

h1 {
  font-family: "magistralcbold";
  font-size: 38px;
  text-transform: uppercase;
  color: #293333;
  line-height: 1;
  margin: 0 0 35px; }
  @media screen and (max-width: 1440px) {
    h1 {
      font-size: 36px;
      margin-bottom: 25px; } }
  @media screen and (max-width: 1200px) {
    h1 {
      font-size: 32px; } }
  @media screen and (max-width: 1024px) {
    h1 {
      font-size: 28px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 24px;
      margin-bottom: 15px; } }

h2 {
  font-family: "magistralcbold";
  font-size: 36px;
  color: #293333;
  margin: 0 0 35px;
  line-height: 1; }
  @media screen and (max-width: 1440px) {
    h2 {
      font-size: 25px; } }
  @media screen and (max-width: 1200px) {
    h2 {
      font-size: 28px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 1024px) {
    h2 {
      font-size: 24px; } }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 22px;
      margin-bottom: 15px; } }

h3 {
  font-family: "magistralcbold";
  font-size: 24px;
  color: #293333;
  line-height: 1.2; }
  @media screen and (max-width: 1440px) {
    h3 {
      font-size: 22px; } }
  @media screen and (max-width: 1366px) {
    h3 {
      font-size: 20px; } }
  @media screen and (max-width: 1200px) {
    h3 {
      font-size: 18px; } }

h4 {
  font-family: "magistralcbold";
  font-size: 22px;
  color: #293333; }
  @media screen and (max-width: 1440px) {
    h4 {
      font-size: 20px; } }
  @media screen and (max-width: 1200px) {
    h4 {
      font-size: 18px; } }
  @media screen and (max-width: 1024px) {
    h4 {
      font-size: 16px; } }

h5 {
  font-family: "magistralcbold";
  font-size: 18px;
  color: #293333; }
  @media screen and (max-width: 1440px) {
    h5 {
      font-size: 16px; } }

h6 {
  font-family: "magistralcbold";
  font-size: 16px;
  text-transform: uppercase;
  color: #293333; }
  @media screen and (max-width: 1024px) {
    h6 {
      font-size: 14px; } }

p {
  margin: 0 0 15px; }

a {
  color: #293333;
  transition: 300ms;
  text-decoration: none; }
  a:focus {
    color: #293333;
    text-decoration: none; }
  a:hover {
    text-decoration: none;
    color: #ff4d00; }

textarea,
button,
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
  border-radius: 0px;
  -webkit-appearance: none; }

.main, .page {
  position: relative;
  flex: 1 0 auto;
  font-size: 16px;
  line-height: 1.4; }
  .main textarea,
  .main input[type="text"],
  .main input[type="email"],
  .main input[type="search"],
  .main input[type="password"], .page textarea,
  .page input[type="text"],
  .page input[type="email"],
  .page input[type="search"],
  .page input[type="password"] {
    min-height: 44px;
    width: 100%;
    background-color: #fff;
    font-family: "ubunturegular";
    font-size: 16px;
    color: #293333;
    padding: 12px 20px;
    margin-bottom: 15px;
    border: 1px solid #d5d5d5;
    box-shadow: none; }
    .main textarea::placeholder,
    .main input[type="text"]::placeholder,
    .main input[type="email"]::placeholder,
    .main input[type="search"]::placeholder,
    .main input[type="password"]::placeholder, .page textarea::placeholder,
    .page input[type="text"]::placeholder,
    .page input[type="email"]::placeholder,
    .page input[type="search"]::placeholder,
    .page input[type="password"]::placeholder {
      color: #949999; }
  @media screen and (max-width: 1440px) {
    .main, .page {
      font-size: 14px; } }
  @media screen and (max-width: 1200px) {
    .main, .page {
      line-height: 1.2; }
      .main textarea,
      .main input[type="text"],
      .main input[type="email"],
      .main input[type="search"],
      .main input[type="password"], .page textarea,
      .page input[type="text"],
      .page input[type="email"],
      .page input[type="search"],
      .page input[type="password"] {
        min-height: 40px;
        font-size: 14px;
        padding: 10px 15px; } }

.main .container {
  padding-top: 115px;
  padding-bottom: 115px; }
  @media screen and (max-width: 1440px) {
    .main .container {
      padding-top: 90px;
      padding-bottom: 90px; } }
  @media screen and (max-width: 1366px) {
    .main .container {
      padding-top: 70px;
      padding-bottom: 70px; } }
  @media screen and (max-width: 1200px) {
    .main .container {
      padding-top: 50px;
      padding-bottom: 50px; } }
  @media screen and (max-width: 1024px) {
    .main .container {
      padding-top: 30px;
      padding-bottom: 30px; } }

.main h1 {
  font-size: 36px; }

@media screen and (max-width: 1440px) {
  .main h1 {
    font-size: 30px; } }

@media screen and (max-width: 1200px) {
  .main h1 {
    font-size: 28px; } }

@media screen and (max-width: 1024px) {
  .main h1 {
    font-size: 24px; } }

@media screen and (max-width: 768px) {
  .main h1 {
    font-size: 22px; } }

.container {
  width: 1170px;
  padding: 0 15px;
  margin: 0 auto; }
  @media screen and (max-width: 1200px) {
    .container {
      width: auto; } }

.close {
  width: 22px;
  height: 22px;
  position: absolute;
  right: 8px;
  top: 10px;
  opacity: 1;
  outline: none; }
  .close:before, .close:after {
    content: '';
    width: 22px;
    height: 2px;
    background-color: #ff4d00;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: -1px auto 0;
    transition: 300ms; }
  .close:before {
    transform: rotate(45deg); }
  .close:after {
    transform: rotate(-45deg); }
  .close:hover:before {
    transform: rotate(135deg); }
  .close:hover:after {
    transform: rotate(45deg); }

.btn {
  display: table;
  height: 44px;
  width: auto !important;
  background-color: #ff4d00 !important;
  font-family: "magistralcbold" !important;
  font-size: 16px;
  color: #fff !important;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  border-radius: 0px;
  border: 2px solid #ff4d00;
  padding: 12px 40px 12px !important;
  line-height: 1;
  transition: 300ms; }
  .btn:focus, .btn:active {
    background-color: #ff4d00 !important;
    color: #fff; }
  .btn:hover, .btn.active {
    background-color: #ff4d00 !important;
    color: #fff !important;
    opacity: 0.6; }
  @media screen and (max-width: 1200px) {
    .btn {
      height: 40px;
      font-size: 14px;
      padding: 10px 25px 10px !important; } }

input.btn {
  padding: 12px 40px 12px !important; }
  @media screen and (max-width: 1200px) {
    input.btn {
      height: 40px;
      font-size: 14px;
      padding: 10px 25px 10px !important; } }

.check--list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .check--list li {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 5px; }
    .check--list li:before {
      content: '\f00c';
      position: absolute;
      font-family: 'fontAwesome';
      font-size: 18px;
      color: #ff4d00;
      left: 0;
      top: 0px; }

.arr--list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .arr--list li {
    position: relative;
    padding-left: 16px;
    margin: 13px 0; }
    .arr--list li:after {
      content: '\f105';
      font-family: 'fontAwesome';
      font-size: 18px;
      color: #ff4d00;
      position: absolute;
      top: 1px;
      left: 0; }
  @media screen and (max-width: 1200px) {
    .arr--list li:after {
      font-size: 16px;
      top: 0px; } }
  @media screen and (max-width: 1024px) {
    .arr--list li {
      margin: 10px 0; } }

.col--location,
.col--phone,
.col--email,
.col--mob {
  position: relative;
  padding-left: 25px; }
  .col--location:after,
  .col--phone:after,
  .col--email:after,
  .col--mob:after {
    content: '';
    font-family: 'fontAwesome';
    font-size: 15px;
    color: #ff4d00;
    position: absolute;
    top: 4px;
    left: 0; }
  @media screen and (max-width: 1440px) {
    .col--location:after,
    .col--phone:after,
    .col--email:after,
    .col--mob:after {
      top: 2px; } }
  @media screen and (max-width: 1024px) {
    .col--location,
    .col--phone,
    .col--email,
    .col--mob {
      padding-left: 20px; } }

.col--location:after {
  content: '\f041'; }

.col--phone:after {
  content: '\f095'; }

.col--phone a {
  display: table;
  pointer-events: none; }

@media screen and (max-width: 1440px) {
  .col--phone:after {
    top: 3px; } }

@media screen and (max-width: 1024px) {
  .col--phone a {
    pointer-events: auto; } }

.col--email:after {
  content: '\f0e0';
  font-size: 13px;
  top: 5px; }

.col--email a {
  text-decoration: underline; }
  .col--email a:hover {
    text-decoration: none; }

@media screen and (max-width: 1440px) {
  .col--email:after {
    top: 3px; } }

@media screen and (max-width: 1024px) {
  .col--email a {
    pointer-events: auto; } }

.col--mob:after {
  content: '\f10b';
  font-size: 20px;
  top: 0px; }

@media screen and (max-width: 1024px) {
  .col--mob:after {
    top: -1px; } }

.bg {
  background-color: #293333;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; }
  .bg #particles-js {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.figure {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 15px 0;
  overflow: hidden;
  z-index: 1; }
  .figure:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1; }
  .figure.left {
    padding-left: 85px;
    padding-right: 40px; }
    .figure.left:before {
      right: -100px;
      left: 35px;
      border-radius: 10px 0 0 0;
      transform: skew(-35deg); }
  .figure.right {
    padding-right: 85px;
    padding-left: 40px; }
    .figure.right:before {
      left: -100px;
      right: 35px;
      border-radius: 0 10px 0 0;
      transform: skew(35deg); }
  .figure.orange {
    color: #fff; }
    .figure.orange:before {
      background-color: #ff4d00; }
  .figure.light:before {
    background-color: #f7f7f7; }
  .figure.white:before {
    background-color: #ffff; }
  .figure.gray {
    color: #fff; }
    .figure.gray:before {
      background-color: #293333; }
  @media screen and (max-width: 1440px) {
    .figure {
      padding: 12px 0; }
      .figure.right {
        padding-left: 25px;
        padding-right: 50px; }
        .figure.right:before {
          right: 15px; }
      .figure.left {
        padding-left: 50px;
        padding-right: 25px; }
        .figure.left:before {
          left: 15px; } }
  @media screen and (max-width: 1024px) {
    .figure {
      padding: 10px 0; }
      .figure.right {
        padding-left: 15px;
        padding-right: 35px; }
      .figure.left {
        padding-left: 35px;
        padding-right: 15px; } }

.bg--white {
  background: #ffffff url(../img/img-4.png); }

.bg--gray {
  background-color: #f7f7f7; }

/* header */
header {
  background-color: #fff;
  font-size: 14px;
  padding-bottom: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  box-shadow: 0px 0px 15px #293333;
  /* header logo */
  /* header logo */
  /* header nav */
  /* header nav */ }
  header:before {
    content: '';
    height: 33px;
    background-color: #ff4d00;
    position: absolute;
    top: 41px;
    left: -100px;
    right: calc(50% + 550px);
    border-radius: 0 0 10px 0;
    transform: skew(-35deg); }
  header ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  header .logo {
    width: 140px;
    display: block;
    position: absolute;
    left: 75px;
    top: 17px; }
    header .logo img {
      display: block;
      max-width: 100%; }
  header .nav {
    width: calc(100% + 85px);
    height: 50px;
    padding-right: 0;
    margin-left: -85px;
    overflow: inherit; }
    header .nav.left {
      padding-right: 0; }
    header .nav:before {
      width: 100vw;
      right: auto; }
    header .nav > ul {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between; }
      header .nav > ul > li > a {
        font-weight: normal;
        font-family: "magistralcbold";
        font-size: 16px;
        color: #fff; }
        header .nav > ul > li > a:hover {
          color: #ff4d00; }
      header .nav > ul > li.active > a {
        color: #ff4d00; }
      header .nav > ul > li ul {
        display: none; }
  header .h--cts {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    font-family: "ubuntulight";
    position: relative;
    z-index: 2; }
    header .h--cts .close {
      display: none; }
    header .h--cts p {
      margin-bottom: 0; }
    header .h--cts .col--phone {
      position: relative;
      padding-right: 15px; }
      header .h--cts .col--phone .h--arr {
        height: 8px;
        font-family: 'fontAwesome';
        font-size: 14px;
        color: #333333;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        line-height: 1;
        pointer-events: auto;
        z-index: 1; }
        header .h--cts .col--phone .h--arr:after {
          content: '\f107'; }
      header .h--cts .col--phone ul {
        display: none;
        background-color: rgba(41, 51, 51, 0.8);
        padding: 5px 10px;
        position: absolute;
        left: 10px;
        top: 100%;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
        z-index: 1; }
        header .h--cts .col--phone ul li {
          margin: 5px 0; }
          header .h--cts .col--phone ul li a {
            white-space: nowrap;
            color: #fff; }
      header .h--cts .col--phone.open .h--arr {
        transform: rotate(-180deg) translateY(50%); }
  header .h--cts--btn {
    display: none;
    font-family: 'fontAwesome';
    font-size: 20px;
    color: #ff4d00;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%); }
    header .h--cts--btn:after {
      content: '\f095'; }
    header .h--cts--btn:hover {
      text-decoration: none; }
  header .col--location,
  header .col--phone {
    padding-left: 21px; }
    header .col--location:after,
    header .col--phone:after {
      font-size: 14px;
      top: 1px; }
  header .col--email {
    padding-left: 21px; }
    header .col--email:after {
      font-size: 12px;
      top: 3px; }
  header .container {
    position: relative;
    padding-left: 300px; }
  @media screen and (max-width: 1024px) {
    header .container {
      padding-left: 250px; }
    header .logo {
      left: 15px; } }
  @media screen and (max-width: 768px) {
    header {
      background-color: #fff;
      padding: 0; }
      header .container {
        height: 80px;
        padding-left: 15px; }
      header .logo {
        width: 110px;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin: 0 auto;
        transform: translateY(-50%); }
        header .logo img {
          width: 100%; }
      header .nav {
        display: none;
        background-color: #293333;
        height: auto;
        max-height: calc(100% - 80px);
        width: 100%;
        position: fixed;
        top: 80px;
        left: 0;
        right: 0;
        overflow: auto;
        padding: 0px !important;
        margin: 0;
        box-shadow: 0px 4px 7px rgba(255, 77, 0, 0.7);
        z-index: 1; }
        header .nav.figure:before {
          display: none; }
        header .nav > ul {
          flex-direction: column;
          padding: 15px; }
          header .nav > ul > li {
            text-align: center;
            margin: 5px 0; }
            header .nav > ul > li ul {
              padding: 10px 0; }
              header .nav > ul > li ul li {
                margin: 7px 0; }
                header .nav > ul > li ul li a {
                  font-size: 14px;
                  color: #fff; }
                  header .nav > ul > li ul li a:hover {
                    color: #ff4d00; }
                header .nav > ul > li ul li.active a {
                  color: #ff4d00; }
      header .nav--btn {
        width: 35px;
        height: 30px;
        position: absolute;
        left: 10px;
        top: 50%;
        padding: 0;
        margin: 0;
        outline: none;
        float: none;
        transform: translateY(-50%); }
        header .nav--btn span {
          position: absolute;
          left: 0;
          right: 0;
          width: 85%;
          height: 3px;
          margin: 0 auto;
          background-color: #ff4d00;
          transition: 350ms; }
          header .nav--btn span:nth-of-type(1) {
            top: 7px; }
          header .nav--btn span:nth-of-type(2) {
            top: 14px; }
          header .nav--btn span:nth-of-type(3) {
            top: 21px; }
        header .nav--btn.open span:nth-of-type(1) {
          top: 14px;
          transform: rotate(45deg); }
        header .nav--btn.open span:nth-of-type(2) {
          opacity: 0; }
        header .nav--btn.open span:nth-of-type(3) {
          top: 14px;
          transform: rotate(-45deg); }
      header .h--cts {
        display: none;
        height: auto;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 10px 25px 15px;
        box-shadow: 0px 0px 10px #293333; }
        header .h--cts .h--cts__item {
          display: table;
          margin: 15px auto; }
        header .h--cts .col--location {
          text-align: center; }
        header .h--cts .col--phone {
          margin-bottom: 0; }
          header .h--cts .col--phone .h--arr {
            display: none; }
          header .h--cts .col--phone ul {
            display: block;
            background-color: transparent;
            position: relative;
            top: 0;
            left: 0;
            box-shadow: none;
            padding: 0; }
            header .h--cts .col--phone ul li {
              margin: 7px 0; }
              header .h--cts .col--phone ul li a {
                color: #293333; }
                header .h--cts .col--phone ul li a:hover {
                  color: #ff4d00; }
        header .h--cts .close {
          display: block;
          right: 5px;
          top: 5px; }
      header .h--cts--btn {
        display: block; } }
  @media screen and (max-width: 420px) {
    header .h--cts .col--location {
      width: 240px; } }

/* header */
/* main content */
.modal-open {
  overflow-y: visible; }
  .modal-open .modal {
    overflow: auto; }

.fancybox-enabled {
  overflow: visible; }
  .fancybox-enabled body {
    overflow-x: hidden; }

/* main slider */
.slider {
  margin-bottom: 0px !important; }
  .slider .slick-slide {
    height: 600px;
    overflow: hidden;
    position: relative;
    outline: none; }
    .slider .slick-slide .slider__block {
      width: 1024px;
      position: absolute;
      left: 50%;
      top: 50%;
      font-size: 18px;
      color: #ffffff;
      margin-left: -570px;
      transform: translateY(-75%); }
      .slider .slick-slide .slider__block .slider__block--title {
        display: block;
        font-family: "magistralcbold";
        font-size: 60px;
        line-height: 1.2;
        margin-bottom: 20px; }
        .slider .slick-slide .slider__block .slider__block--title span {
          display: block;
          font-size: 36px; }
      .slider .slick-slide .slider__block .btn {
        margin-top: 40px; }
  .slider .slick-dots {
    width: auto;
    right: auto;
    left: 50%;
    bottom: 100px;
    margin-left: -570px;
    line-height: 1; }
    .slider .slick-dots li {
      width: auto;
      height: auto;
      margin: 0 18px 0 0; }
      .slider .slick-dots li span {
        display: block;
        width: auto;
        height: auto;
        font-family: "magistralcbold";
        font-size: 24px;
        color: #949999;
        padding-bottom: 8px;
        border-bottom: 2px solid transparent;
        transition: 300ms; }
        .slider .slick-dots li span:before {
          display: none; }
        .slider .slick-dots li span:hover {
          color: #ff4d00;
          border-bottom: 2px solid #ff4d00; }
      .slider .slick-dots li.slick-active span {
        color: #ff4d00;
        border-bottom: 2px solid #ff4d00; }
  @media screen and (max-width: 1440px) {
    .slider .slick-slide {
      height: 450px; }
      .slider .slick-slide .slider__block {
        font-size: 16px; }
        .slider .slick-slide .slider__block .slider__block--title {
          font-size: 48px; }
          .slider .slick-slide .slider__block .slider__block--title span {
            font-size: 28px; }
    .slider .slick-dots {
      bottom: 50px; } }
  @media screen and (max-width: 1366px) {
    .slider .slick-slide {
      height: 400px; }
      .slider .slick-slide .slider__block .slider__block--title {
        font-size: 38px; }
        .slider .slick-slide .slider__block .slider__block--title span {
          font-size: 24px; }
    .slider .slick-dots {
      bottom: 30px; } }
  @media screen and (max-width: 1200px) {
    .slider .slick-slide .slider__block {
      width: auto;
      left: 20px;
      right: 20px;
      margin: 0; }
      .slider .slick-slide .slider__block .slider__block--title {
        font-size: 30px; }
        .slider .slick-slide .slider__block .slider__block--title span {
          font-size: 20px; }
      .slider .slick-slide .slider__block .btn {
        margin-top: 30px; }
    .slider .slick-dots {
      left: 20px;
      margin: 0; }
      .slider .slick-dots li span {
        font-size: 20px;
        padding-bottom: 5px; } }
  @media screen and (max-width: 1024px) {
    .slider .slick-slide {
      height: auto; }
      .slider .slick-slide .slider__block {
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        transform: translateY(0);
        padding: 30px 20px 75px; }
    .slider .slick-dots {
      bottom: 20px; } }
  @media screen and (max-width: 768px) {
    .slider .slick-slide .slider__block .slider__block--title {
      font-size: 24px; }
      .slider .slick-slide .slider__block .slider__block--title span {
        font-size: 18px; } }
  @media screen and (max-width: 600px) {
    .slider .slick-slide .slider__block {
      font-size: 14px; }
      .slider .slick-slide .slider__block .slider__block--title {
        font-size: 22px;
        margin-bottom: 15px; }
        .slider .slick-slide .slider__block .slider__block--title span {
          font-size: 17px; }
      .slider .slick-slide .slider__block .btn {
        margin-top: 20px; } }

/* main slider */
/* main package */
.package .container {
  position: relative; }

.package .more {
  position: absolute;
  right: 10px;
  top: 128px; }

@media screen and (max-width: 1440px) {
  .package .more {
    top: 95px; } }

@media screen and (max-width: 1366px) {
  .package .more {
    top: 76px; } }

@media screen and (max-width: 1200px) {
  .package .more {
    top: 60px; } }

@media screen and (max-width: 1024px) {
  .package .more {
    top: 37px; } }

@media screen and (max-width: 768px) {
  .package .more {
    top: 35px; } }

@media screen and (max-width: 600px) {
  .package .more {
    position: relative;
    top: 0;
    right: auto;
    left: 0;
    margin-bottom: 15px; } }

.package__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .package__block .package__item {
    width: calc(50% - 15px);
    transition: 0ms;
    position: relative;
    padding-top: 48px;
    margin-bottom: 20px; }
    .package__block .package__item:nth-last-of-type(-n+2) {
      margin-bottom: 0; }
    .package__block .package__item .date {
      font-family: "ubuntulight";
      position: absolute;
      top: 0px;
      left: 0;
      line-height: 1.2; }
    .package__block .package__item .package__info {
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.9);
      border: 1px solid #ff4d00;
      padding: 30px 30px;
      transition: 300ms; }
      .package__block .package__item .package__info h3 {
        font-size: 20px;
        margin: 0;
        transition: 300ms; }
      .package__block .package__item .package__info p {
        margin: 15px 0 0 0; }
    .package__block .package__item:hover {
      color: #fff; }
      .package__block .package__item:hover .package__info {
        background-color: #293333; }
        .package__block .package__item:hover .package__info h3 {
          color: #fff; }
  @media screen and (max-width: 1440px) {
    .package__block .package__item {
      padding-top: 40px; }
      .package__block .package__item .package__info {
        padding: 30px 20px; } }
  @media screen and (max-width: 1024px) {
    .package__block {
      margin: 0 -10px; }
      .package__block .package__item {
        width: calc(50% - 20px);
        padding-top: 35px;
        margin: 0 10px 20px; }
        .package__block .package__item .package__info {
          padding: 20px 15px; }
          .package__block .package__item .package__info h3 {
            font-size: 18px; } }
  @media screen and (max-width: 600px) {
    .package__block {
      margin: 0px; }
      .package__block .package__item {
        width: 100%;
        margin: 0 0 15px; }
        .package__block .package__item:nth-last-of-type(2n) {
          margin-bottom: 15px; }
        .package__block .package__item .package__info h3 {
          font-size: 16px; } }

.more {
  display: table;
  font-family: "magistralcbold";
  text-transform: uppercase;
  color: #ff4d00;
  padding-right: 20px;
  position: relative;
  line-height: 1.1; }
  .more:before, .more:after {
    content: '\f105';
    font-family: 'fontAwesome';
    font-size: 16px;
    color: #ff4d00;
    position: absolute;
    right: 0;
    top: 0;
    transition: 300ms; }
  .more:after {
    right: 7px; }
  .more:hover:after {
    right: 2px; }
  .more:hover:before {
    right: -5px; }
  @media screen and (max-width: 1440px) {
    .more:before, .more:after {
      top: -2px; } }
  @media screen and (max-width: 768px) {
    .more:before, .more:after {
      top: 0px; } }

/* main package */
/* main cat */
.cat .container {
  padding-top: 0; }

.cat__block {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }
  .cat__block .cat__item {
    height: 265px;
    width: calc(25% - 30px);
    background-color: rgba(255, 255, 255, 0.9);
    text-align: center;
    margin: 0 15px 30px;
    position: relative; }
    .cat__block .cat__item:nth-last-child(-n+4) {
      margin-bottom: 0; }
    .cat__block .cat__item .decor {
      border: 1px solid #ff4d00;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: 300ms; }
      .cat__block .cat__item .decor:before, .cat__block .cat__item .decor:after {
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid #ff4d00;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        position: absolute;
        left: 50px;
        top: 0;
        transition: 300ms; }
      .cat__block .cat__item .decor:after {
        top: auto;
        left: auto;
        bottom: 0;
        right: 50px;
        border-bottom: 10px solid #ff4d00;
        border-top: none; }
    .cat__block .cat__item .cat__txt {
      position: absolute;
      top: 50%;
      left: 30px;
      right: 30px;
      transform: translateY(-50%); }
    .cat__block .cat__item h4 {
      transition: 300ms;
      margin: 0; }
    .cat__block .cat__item .cat--more {
      display: block;
      width: 35px;
      height: 30px;
      font-family: 'fontAwesome';
      font-size: 36px;
      color: #fff;
      margin: 10px auto 0;
      position: relative; }
      .cat__block .cat__item .cat--more:before, .cat__block .cat__item .cat--more:after {
        content: '\f105';
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%); }
      .cat__block .cat__item .cat--more:after {
        right: 5px; }
    .cat__block .cat__item.last {
      background-color: #293333; }
      .cat__block .cat__item.last .decor {
        top: 18px;
        right: 18px;
        bottom: 18px;
        left: 18px; }
      .cat__block .cat__item.last h4 {
        color: #fff; }
    .cat__block .cat__item:hover {
      background-color: #ff4d00; }
      .cat__block .cat__item:hover .decor {
        top: 18px;
        bottom: 18px;
        left: 18px;
        right: 18px;
        border: 1px solid #fff; }
        .cat__block .cat__item:hover .decor:before {
          border-top: 10px solid #fff; }
        .cat__block .cat__item:hover .decor:after {
          border-bottom: 10px solid #fff; }
      .cat__block .cat__item:hover h4 {
        color: #fff; }
  @media screen and (max-width: 1024px) {
    .cat__block {
      margin: 0 -10px; }
      .cat__block .cat__item {
        width: calc(25% - 20px);
        height: 215px;
        margin: 0 10px 20px; }
        .cat__block .cat__item .cat--more {
          height: 18px;
          font-size: 24px;
          margin-top: 5px; }
          .cat__block .cat__item .cat--more:after {
            left: 15px; }
          .cat__block .cat__item .cat--more:before {
            left: 10px; }
        .cat__block .cat__item.last .decor {
          top: 10px;
          right: 10px;
          bottom: 10px;
          left: 10px; }
        .cat__block .cat__item:hover .decor {
          top: 10px;
          right: 10px;
          left: 10px;
          bottom: 10px; } }
  @media screen and (max-width: 768px) {
    .cat__block .cat__item {
      width: calc(33.33% - 20px); }
      .cat__block .cat__item .decor:before {
        left: 20px; }
      .cat__block .cat__item .decor:after {
        right: 20px; } }
  @media screen and (max-width: 600px) {
    .cat__block .cat__item {
      width: calc(50% - 20px); }
      .cat__block .cat__item:nth-last-child(3n), .cat__block .cat__item:nth-last-of-type(4n) {
        margin-bottom: 20px; } }
  @media screen and (max-width: 420px) {
    .cat__block .cat__item {
      height: 180px; }
      .cat__block .cat__item .cat__txt {
        left: 20px;
        right: 20px; } }
  @media screen and (max-width: 340px) {
    .cat__block .cat__item {
      height: 150px; }
      .cat__block .cat__item h4 {
        font-size: 14px; } }

/* main cat */
/* main about */
.about {
  overflow: hidden; }
  .about .container {
    padding-top: 70px;
    padding-bottom: 0; }
    .about .container.figure {
      display: block;
      overflow: visible;
      padding-left: 15px;
      padding-right: 15px; }
      .about .container.figure.left:before {
        width: 120vw;
        left: -235px; }
  @media screen and (max-width: 1440px) {
    .about .container.figure.left:before {
      width: 160vw;
      left: -200px; } }
  @media screen and (max-width: 1200px) {
    .about .container {
      padding-top: 50px; }
      .about .container.figure.left:before {
        width: 160vw; } }
  @media screen and (max-width: 1024px) {
    .about .container {
      padding-top: 30px; }
      .about .container.figure {
        background-color: #f7f7f7; }
        .about .container.figure:before {
          display: none; } }

.about__block {
  display: flex;
  justify-content: space-between; }
  .about__block .left {
    width: calc(100% - 470px);
    padding-right: 100px; }
  .about__block .right {
    max-width: 470px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .about__block .right img {
      display: block;
      max-width: 100%; }
  @media screen and (max-width: 1440px) {
    .about__block .left {
      width: calc(100% - 300px); }
    .about__block .right {
      max-width: 300px; } }
  @media screen and (max-width: 1024px) {
    .about__block .left {
      padding-right: 50px; } }
  @media screen and (max-width: 768px) {
    .about__block {
      flex-wrap: wrap; }
      .about__block .left {
        width: 100%;
        padding-right: 0; }
      .about__block .right {
        width: 100%;
        max-width: 100%;
        margin: 10px 0; }
        .about__block .right img {
          display: table;
          margin: 0 auto; } }

.about__slogan {
  font-style: italic;
  padding-top: 23px;
  padding-bottom: 23px;
  line-height: 1.4;
  margin-top: 45px; }
  .about__slogan.figure {
    overflow: visible; }
    .about__slogan.figure.left:before {
      border-radius: 25px 0 0 0; }
    .about__slogan.figure:before {
      width: 100vw; }
  .about__slogan * {
    margin: 0; }
  @media screen and (max-width: 1440px) {
    .about__slogan.figure.left {
      padding-left: 65px; } }
  @media screen and (max-width: 1200px) {
    .about__slogan {
      margin-top: 35px; } }
  @media screen and (max-width: 1024px) {
    .about__slogan {
      margin-top: 20px; } }
  @media screen and (max-width: 768px) {
    .about__slogan.figure {
      background-color: #293333;
      padding: 20px !important; }
      .about__slogan.figure.left:before {
        display: none; } }

/* main about */
/* main news */
.news__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .news__block .news__item {
    width: calc(50% - 15px);
    color: #293333;
    margin-bottom: 30px; }
    .news__block .news__item:nth-last-child(-n+2) {
      margin-bottom: 0; }
    .news__block .news__item .news__img {
      height: 312px;
      overflow: hidden;
      position: relative;
      margin-bottom: 35px; }
      .news__block .news__item .news__img img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        transition: 300ms; }
      .news__block .news__item .news__img .date {
        font-family: "ubuntulight";
        position: absolute;
        left: 0;
        bottom: 0; }
    .news__block .news__item h3 {
      margin: 0 0 15px 0;
      transition: 300ms; }
    .news__block .news__item p {
      margin-bottom: 0; }
    .news__block .news__item:hover .news__img img {
      transform: scale(1.1); }
    .news__block .news__item:hover h3 {
      color: #ff4d00; }
  @media screen and (max-width: 1200px) {
    .news__block .news__item .news__img {
      height: 300px; } }
  @media screen and (max-width: 1024px) {
    .news__block .news__item .news__img {
      height: 255px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 768px) {
    .news__block .news__item .news__img {
      height: 180px;
      margin-bottom: 10px; }
    .news__block .news__item h3 {
      margin-bottom: 10px; } }
  @media screen and (max-width: 600px) {
    .news__block .news__item {
      width: 100%;
      margin-bottom: 20px; }
      .news__block .news__item:nth-last-child(-n+2) {
        margin-bottom: 20px; }
      .news__block .news__item:last-of-type {
        margin-bottom: 0; }
      .news__block .news__item .news__img {
        height: auto; }
        .news__block .news__item .news__img img {
          height: auto; } }

/* main news */
/* main content */
/* page content */
.page .news1 .date, .page .news .date {
  color: #ff4d00; }

.page .page_nav .active {
  color: #ff4d00; }

.page #ask-form {
  max-width: 350px; }

.page .write {
  color: #ff4d00;
  text-decoration: none; }
  .page .write:hover {
    text-decoration: underline; }

.page .back_link {
  margin: 25px 0; }

.page .bg--white, .page .bg--gray {
  padding-bottom: 115px;
  padding-top: 70px; }

.page.page--about .bg--white {
  padding-top: 115px; }

.page p a {
  color: #ff4d00;
  text-decoration: underline; }
  .page p a:hover {
    text-decoration: none; }

.page h2 a {
  font-size: 16px;
  color: #ff4d00; }

@media screen and (max-width: 1440px) {
  .page .bg--white, .page .bg--gray {
    padding-bottom: 100px;
    padding-top: 70px; } }

@media screen and (max-width: 1200px) {
  .page .bg--white, .page .bg--gray {
    padding-bottom: 75px;
    padding-top: 50px; } }

@media screen and (max-width: 1024px) {
  .page #photos-list td img {
    width: 100%;
    height: auto; }
  .page .bg--white, .page .bg--gray {
    padding-bottom: 50px;
    padding-top: 30px; } }

@media screen and (max-width: 768px) {
  .page .bg--white, .page .bg--gray {
    padding-top: 20px;
    padding-bottom: 30px; } }

@media screen and (max-width: 480px) {
  .page p img, .page p strong img, .page h2 img, .page h3 img, .page h4 img, .page h5 img, .page h6 img, .page p a img, .page strong img {
    width: 100% !important;
    height: auto !important;
    display: block !important;
    float: none !important;
    margin: 0 0 10px 0 !important; }
  .page #page-text p a img {
    width: auto !important;
    max-width: 100% !important; }
  .page #photos-list td {
    width: 50%;
    display: inline-block;
    vertical-align: top; } }

/* breadcrumbs */
.breadcrumbs {
  font-weight: normal;
  font-size: 14px;
  padding: 30px 0 40px; }
  .breadcrumbs ul {
    min-height: 40px;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 15px; }
    .breadcrumbs ul li {
      padding: 5px 0px;
      color: #ff4d00;
      display: inline-block;
      vertical-align: top; }
      .breadcrumbs ul li a {
        color: #cccccc;
        text-decoration: none; }
        .breadcrumbs ul li a:hover {
          color: #ff4d00; }
      .breadcrumbs ul li:after {
        content: '/';
        font-size: 14px;
        margin: 1px 3px 0px 8px;
        color: #cccccc;
        display: inline-block;
        vertical-align: top; }
      .breadcrumbs ul li:last-of-type:after {
        display: none; }
  .breadcrumbs h1 {
    color: #fff;
    text-transform: inherit;
    margin: 0; }
  @media screen and (max-width: 1200px) {
    .breadcrumbs {
      padding: 20px 0 30px; }
      .breadcrumbs ul {
        min-height: 0px;
        margin-bottom: 10px; } }
  @media screen and (max-width: 1024px) {
    .breadcrumbs {
      font-size: 14px;
      padding: 15px 0 20px; }
      .breadcrumbs ul li:after {
        font-size: 12px;
        margin: 2px 5px 0 7px; } }
  @media screen and (max-width: 768px) {
    .breadcrumbs {
      font-size: 12px; }
      .breadcrumbs ul li {
        padding-top: 2px;
        padding-bottom: 2px; }
        .breadcrumbs ul li:after {
          font-size: 12px; }
      .breadcrumbs h1 {
        font-size: 20px; } }

/* breadcrumbs */
/* page about */
.page .about__slogan {
  display: block;
  margin-top: -60px;
  margin-bottom: 35px; }

.page.page--about .bg--white {
  padding-bottom: 0;
  border-bottom: 115px solid #fff; }

.page.page--about .container.figure {
  display: block;
  overflow: visible; }
  .page.page--about .container.figure:before {
    width: 150vw;
    left: -15rem;
    box-shadow: -3px -3px 3px rgba(0, 0, 0, 0.06); }
  .page.page--about .container.figure.left {
    padding-left: 15px;
    padding-right: 15px; }

@media screen and (max-width: 1440px) {
  .page.page--about .bg--white {
    padding-top: 100px;
    border-bottom: 100px solid #fff; } }

@media screen and (max-width: 1200px) {
  .page.page--about .container.figure.left {
    padding-top: 0;
    padding-bottom: 0; }
  .page.page--about .container.figure.white {
    background-color: #fff; }
  .page.page--about .container.figure:before {
    display: none; }
  .page.page--about .bg--white {
    padding-top: 75px;
    border-bottom: 75px solid #fff; }
  .page .about__slogan {
    margin: 0 0 20px; } }

@media screen and (max-width: 1024px) {
  .page.page--about .bg--white {
    padding-top: 30px;
    border-bottom: 50px solid #fff; } }

@media screen and (max-width: 768px) {
  .page.page--about .bg--white {
    padding-top: 20px;
    border-bottom: 30px solid #fff; } }

.about__info {
  display: flex;
  margin-bottom: 30px; }
  .about__info .left {
    display: flex;
    width: 485px;
    align-items: center;
    padding-right: 100px; }
    .about__info .left img {
      display: block;
      max-width: 100%; }
  .about__info .right {
    width: calc(100% - 485px); }
  .about__info .about__list {
    display: flex; }
    .about__info .about__list ul {
      min-width: 30%;
      padding-right: 15px; }
  @media screen and (max-width: 1200px) {
    .about__info .left {
      width: 385px;
      padding-right: 50px; }
    .about__info .right {
      width: calc(100% - 385px); } }
  @media screen and (max-width: 1024px) {
    .about__info {
      margin-bottom: 20px; }
      .about__info .left {
        width: 285px; }
      .about__info .right {
        width: calc(100% - 285px); } }
  @media screen and (max-width: 600px) {
    .about__info {
      flex-wrap: wrap; }
      .about__info .left {
        width: 100%;
        padding-right: 0; }
        .about__info .left img {
          display: block;
          margin: 0px auto 15px; }
      .about__info .right {
        width: 100%; } }
  @media screen and (max-width: 480px) {
    .about__info .about__list {
      flex-wrap: wrap; }
      .about__info .about__list ul {
        width: 100%;
        padding-right: 0; }
      .about__info .about__list .arr--list li {
        margin: 0 0 10px; } }

/* page about */
/* page schedule */
.tabs {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  line-height: 1;
  margin-bottom: 30px; }
  .tabs ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: calc(100% - 345px);
    border-bottom: 1px solid #e2e3e3; }
    .tabs ul li {
      margin-right: 60px; }
      .tabs ul li a {
        display: block;
        font-family: "magistralcbold";
        font-size: 20px;
        color: #a5a9a9;
        position: relative;
        padding: 11px 0; }
        .tabs ul li a:after {
          content: '';
          width: 115px;
          height: 2px;
          background-color: #ff4d00;
          position: absolute;
          left: 0;
          bottom: -1px;
          opacity: 0; }
        .tabs ul li a:hover {
          color: #293333; }
      .tabs ul li.active a {
        color: #293333; }
        .tabs ul li.active a:after {
          opacity: 1; }
  .tabs .btn {
    padding-left: 90px !important;
    padding-right: 90px !important; }
  @media screen and (max-width: 1200px) {
    .tabs ul li a {
      font-size: 18px; } }
  @media screen and (max-width: 1024px) {
    .tabs {
      align-items: flex-end; }
      .tabs ul {
        width: calc(100% - 235px); }
        .tabs ul li {
          margin-right: 30px; }
          .tabs ul li a {
            font-size: 16px; }
            .tabs ul li a:after {
              width: 75px; }
      .tabs .btn {
        padding-left: 40px !important;
        padding-right: 40px !important; } }
  @media screen and (max-width: 600px) {
    .tabs {
      flex-wrap: wrap;
      margin-bottom: 15px; }
      .tabs ul {
        width: 100%; }
        .tabs ul li {
          margin-right: 15px; }
          .tabs ul li a {
            font-size: 14px; }
            .tabs ul li a:after {
              width: 50px; }
      .tabs .btn {
        width: 100% !important;
        padding-left: 25px !important;
        padding-right: 25px !important;
        margin-top: 15px; } }

.tabs__block {
  display: none; }

.tabs__table {
  background-color: rgba(255, 255, 255, 0.9);
  margin-bottom: 5px; }
  .tabs__table .tabs__title {
    padding: 10px 50px 10px 30px;
    border: 1px solid #fd804a;
    position: relative;
    cursor: pointer;
    transition: 300ms;
    user-select: none; }
    .tabs__table .tabs__title:before, .tabs__table .tabs__title:after {
      content: '';
      width: 12px;
      height: 2px;
      background-color: #ff4d00;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      transition: 300ms; }
    .tabs__table .tabs__title:after {
      transform: translateY(-50%) rotate(-90deg); }
    .tabs__table .tabs__title h5 {
      color: #293333;
      margin: 0;
      transition: 300ms; }
  .tabs__table .tabs__down {
    display: none;
    border: 1px solid #fd804a;
    border-top: none; }
  .tabs__table.open .tabs__title {
    background-color: #ff4d00; }
    .tabs__table.open .tabs__title:before, .tabs__table.open .tabs__title:after {
      background-color: #fff; }
    .tabs__table.open .tabs__title:after {
      opacity: 0; }
    .tabs__table.open .tabs__title h5 {
      color: #fff; }
  .tabs__table table {
    width: 100%;
    border-collapse: collapse; }
    .tabs__table table tr th {
      position: relative;
      font-family: "ubuntulight";
      font-weight: normal;
      font-size: 12px;
      white-space: nowrap;
      color: #686f6f;
      text-align: left;
      padding: 20px 20px 6px; }
      .tabs__table table tr th:after {
        content: '';
        height: 1px;
        background-color: #e7e8e8;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0; }
      .tabs__table table tr th:first-of-type {
        width: 475px; }
        .tabs__table table tr th:first-of-type:after {
          left: 30px; }
      .tabs__table table tr th:last-of-type:after {
        right: 30px; }
    .tabs__table table tr td {
      padding: 12px 20px;
      color: #293333;
      vertical-align: top;
      transition: 300ms; }
      .tabs__table table tr td a {
        color: #293333;
        text-decoration: underline; }
        .tabs__table table tr td a:hover {
          text-decoration: none; }
    .tabs__table table tr:last-of-type td {
      padding-bottom: 20px; }
    .tabs__table table tr:hover td {
      background-color: #fd804a;
      color: #fff; }
      .tabs__table table tr:hover td a {
        color: #fff; }
    .tabs__table table tr:hover .none {
      background-color: #fff; }
  .tabs__table .none {
    display: block;
    width: 16px;
    height: 1px;
    background-color: #5e6666;
    transition: 300ms; }
  @media screen and (max-width: 600px) {
    .tabs__table .tabs__title {
      padding: 10px 30px 10px 20px; }
      .tabs__table .tabs__title:after, .tabs__table .tabs__title:before {
        right: 13px; } }

.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: auto;
  top: 0;
  left: 5px;
  right: 5px; }

.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin: 15px 0 0; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #ff4d00 !important;
  opacity: 1;
  border-radius: 0px; }

.mCSB_scrollTools .mCSB_draggerRail,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: #293333;
  height: 4px;
  margin: 6px 0;
  border-radius: 0px; }

.mCustomScrollbar,
.mCustomScrollbar.mCS_touch_action,
.mCustomScrollbar.mCS_touch_action .mCS-aviva.mCSB_scrollTools {
  -ms-touch-action: auto;
  touch-action: auto; }

.mCustomScrollBox {
  -ms-touch-action: pan-x pinch-zoom;
  touch-action: pan-x pinch-zoom; }

.mCustomScrollBox.mCSB_vertical_horizontal {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom; }

.mCustomScrollBox.mCSB_horizontal {
  -ms-touch-action: pan-y pinch-zoom;
  touch-action: pan-y pinch-zoom; }

/* page schedule */
/* page catalog */
.page--cat {
  overflow: hidden; }
  .page--cat .bg--white {
    position: relative; }
    .page--cat .bg--white:before {
      content: '';
      background-color: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: calc(50% + 610px); }

.cat--inside {
  display: flex;
  position: relative;
  z-index: 1; }
  .cat--inside .left {
    width: 360px;
    position: relative;
    padding-right: 30px; }
    .cat--inside .left:before {
      content: '';
      height: 144px;
      background-color: #fff;
      position: absolute;
      top: -70px;
      left: -40px;
      right: 0;
      z-index: -1; }
    .cat--inside .left .btn {
      width: calc(100%) !important;
      pointer-events: none; }
    .cat--inside .left ul {
      list-style: none;
      margin: 0;
      padding: 0;
      padding: 30px 0;
      position: relative; }
      .cat--inside .left ul:after {
        content: '';
        background-color: #fff;
        position: absolute;
        left: -40px;
        right: -30px;
        top: calc(100% - 30px);
        height: 1000em; }
      .cat--inside .left ul li a {
        display: block;
        font-family: "magistralcbold";
        font-size: 18px;
        padding: 10px 30px 10px 0;
        position: relative; }
        .cat--inside .left ul li a:before {
          content: '';
          background-color: #fff;
          position: absolute;
          top: 0;
          bottom: 0;
          right: -30px;
          left: -40px;
          z-index: -1;
          transition: 300ms; }
        .cat--inside .left ul li a:after {
          content: '';
          background: url(../img/bg-r.png) no-repeat top left;
          background-size: contain;
          height: 100%;
          width: 39px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: -40px; }
        .cat--inside .left ul li a:hover:before {
          opacity: 0; }
      .cat--inside .left ul li.active a {
        color: #ff4d00; }
        .cat--inside .left ul li.active a:before {
          opacity: 0; }
        .cat--inside .left ul li.active a:after {
          opacity: 1; }
  .cat--inside .right {
    width: calc(100% - 360px);
    padding-left: 30px; }
  @media screen and (max-width: 1200px) {
    .cat--inside .left:before {
      height: 115px;
      top: -50px; } }
  @media screen and (max-width: 1024px) {
    .cat--inside .left {
      width: 280px; }
      .cat--inside .left:before {
        height: 95px;
        top: -30px; }
      .cat--inside .left ul li a {
        font-size: 16px; }
        .cat--inside .left ul li a:after {
          display: none; }
    .cat--inside .right {
      width: calc(100% - 280px); } }
  @media screen and (max-width: 768px) {
    .cat--inside {
      flex-wrap: wrap;
      align-items: flex-start; }
      .cat--inside .left {
        display: none;
        width: 100%;
        background-color: #fff;
        padding: 20px 15px 10px;
        margin-top: 0px;
        margin-bottom: 15px; }
        .cat--inside .left:before {
          display: none; }
        .cat--inside .left ul {
          padding: 15px 0 0; }
          .cat--inside .left ul:after {
            display: none; }
          .cat--inside .left ul li a:before {
            display: none; }
      .cat--inside .right {
        width: 100%;
        padding: 0; } }

.search {
  display: flex;
  overflow: hidden;
  margin-bottom: 30px; }
  .search input[type="search"] {
    width: calc(100% - 82px);
    margin: 0 !important; }
    .search input[type="search"]::-webkit-search-cancel-button {
      appearance: none; }
  .search button {
    width: 82px;
    background-color: #293333;
    border: none;
    padding: 0;
    position: relative;
    cursor: pointer;
    transition: 300ms; }
    .search button:after {
      content: '';
      background: url(../img/search.png) no-repeat center center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .search button:hover {
      background-color: #ff4d00; }
  @media screen and (max-width: 1024px) {
    .search input[type="search"] {
      width: calc(100% - 50px); }
    .search button {
      width: 50px; }
      .search button:after {
        background-size: 22px; } }
  @media screen and (max-width: 768px) {
    .search {
      margin-bottom: 20px; } }

.cat--unit {
  display: flex; }
  .cat--unit .left {
    width: 360px;
    padding-right: 30px; }
    .cat--unit .left p {
      font-size: 14px; }
    .cat--unit .left h3 {
      margin: 0 0 10px; }
    .cat--unit .left .btn {
      width: 100% !important;
      margin: 0 0 15px; }
  .cat--unit .right {
    width: calc(100% - 360px);
    position: relative;
    padding: 0 0 0 30px;
    z-index: 1; }
    .cat--unit .right:before {
      content: '';
      width: 100vw;
      background-color: #fff;
      position: absolute;
      top: -70px;
      left: 0;
      bottom: -115px;
      z-index: -1; }
  .cat--unit .cat--unit__title {
    display: block;
    font-family: "magistralcbold";
    font-size: 16px;
    color: #293333;
    margin-bottom: 13px; }
    .cat--unit .cat--unit__title span {
      color: #ff4d00; }
  .cat--unit .cat--unit__up {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; }
    .cat--unit .cat--unit__up .cat--unit__item .cat--unit__title {
      margin-bottom: 20px; }
  .cat--unit .tabs ul {
    width: 100%; }
  @media screen and (max-width: 1440px) {
    .cat--unit .right:before {
      bottom: -100px; } }
  @media screen and (max-width: 1200px) {
    .cat--unit .right:before {
      width: calc(100% + 15px);
      top: -50px;
      bottom: -75px; } }
  @media screen and (max-width: 1024px) {
    .cat--unit .left {
      width: 330px;
      padding-right: 20px; }
    .cat--unit .right {
      width: calc(100% - 330px); }
      .cat--unit .right:before {
        top: -30px;
        bottom: -50px; } }
  @media screen and (max-width: 768px) {
    .cat--unit {
      flex-wrap: wrap; }
      .cat--unit .left {
        width: 100%;
        padding: 0;
        order: 2; }
      .cat--unit .right {
        width: 100%;
        padding: 0 0 15px;
        order: 1;
        margin-bottom: 15px; }
        .cat--unit .right:before {
          width: calc(100% + 30px);
          top: -20px;
          bottom: 0px;
          left: -15px; }
      .cat--unit .cat--unit__up .cat--unit__item .cat--unit__title {
        margin-bottom: 10px; } }
  @media screen and (max-width: 600px) {
    .cat--unit .cat--unit__title {
      font-size: 14px; }
    .cat--unit .cat--unit__up {
      flex-wrap: wrap; }
      .cat--unit .cat--unit__up .cat--unit__item {
        width: 50%; }
        .cat--unit .cat--unit__up .cat--unit__item .cat--unit__title {
          margin-bottom: 5px; } }
  @media screen and (max-width: 360px) {
    .cat--unit .cat--unit__up {
      flex-wrap: wrap; }
      .cat--unit .cat--unit__up .cat--unit__item {
        width: 100%; } }

.city {
  margin: 25px 0 25px; }
  .city .city__block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px; }
    .city .city__block .city__item {
      display: flex;
      height: 70px;
      width: calc(25% - 10px);
      background-color: rgba(255, 255, 255, 0.9);
      font-family: "magistralcbold";
      font-size: 12px;
      color: #293333;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding: 3px 5px;
      border: 1px solid #ff4d00;
      line-height: 1;
      margin: 0 5px 10px; }
      .city .city__block .city__item .date span {
        display: block;
        font-size: 20px; }
  @media screen and (max-width: 1024px) {
    .city {
      margin: 15px 0; } }

/* page catalog */
/* page contacts */
.cts {
  display: flex;
  margin-top: -70px;
  padding-bottom: 55px; }
  .cts .cts__city {
    width: 50%; }
  .cts .cts__item {
    background-color: #fff;
    padding: 50px 50px 50px 75px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: 300ms;
    position: relative; }
    .cts .cts__item:after {
      content: '';
      width: 16px;
      height: 26px;
      background: url(../img/tringl.png) no-repeat;
      position: absolute;
      left: -16px;
      top: 50%;
      opacity: 0;
      transition: 300ms;
      transform: translateY(-50%); }
    .cts .cts__item h3 {
      margin: 0 0 25px; }
    .cts .cts__item ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      .cts .cts__item ul li {
        color: #666666;
        margin-bottom: 14px; }
        .cts .cts__item ul li a {
          color: #666666; }
          .cts .cts__item ul li a:hover {
            color: #ff4d00; }
    .cts .cts__item:hover {
      border: 1px solid #ff4d00; }
    .cts .cts__item.active {
      border: 1px solid #ff4d00; }
      .cts .cts__item.active:after {
        opacity: 1; }
  @media screen and (max-width: 1440px) {
    .cts .cts__item {
      padding: 30px 30px 30px 50px; } }
  @media screen and (max-width: 1200px) {
    .cts {
      padding-bottom: 35px;
      margin-top: -50px; } }
  @media screen and (max-width: 1024px) {
    .cts {
      padding-bottom: 20px;
      padding-right: 0;
      margin-top: -30px; }
      .cts .cts__item {
        padding: 25px 15px 25px 30px; }
        .cts .cts__item h3 {
          margin-bottom: 15px; } }
  @media screen and (max-width: 768px) {
    .cts {
      margin-top: -20px; } }
  @media screen and (max-width: 600px) {
    .cts {
      flex-wrap: wrap;
      padding-bottom: 10px; }
      .cts .cts__item {
        width: 100%; } }
  @media screen and (max-width: 480px) {
    .cts {
      flex-wrap: wrap;
      padding: 15px 15px 25px; }
      .cts .map {
        width: 100%;
        height: 250px;
        order: 2; }
      .cts .cts__city {
        width: 100%;
        flex-wrap: wrap; }
      .cts .cts__item {
        width: 100%;
        padding: 20px 15px; }
        .cts .cts__item:after {
          display: none; } }

.map {
  width: 50%; }
  .map .map--block {
    display: none;
    height: 100%; }
  .map > ymaps, .map .ymaps-2-1-74-map {
    height: 100% !important; }

.form h3 {
  text-transform: uppercase;
  margin: 0 0 20px; }

.form p {
  color: #666666;
  margin-bottom: 18px; }

.form .form__inp {
  display: flex;
  justify-content: space-between; }
  .form .form__inp input[type="text"],
  .form .form__inp input[type="email"] {
    width: calc(33.33% - 10px); }

.form textarea {
  display: inline-block;
  vertical-align: top;
  min-height: 78px;
  width: calc(66.66% - 5px); }

.form .g-recaptcha {
  display: inline-block;
  vertical-align: top;
  margin-left: 13px; }

.form .btn {
  margin: 0 auto; }

@media screen and (max-width: 1024px) {
  .form .g-recaptcha {
    float: right; } }

@media screen and (max-width: 1024px) {
  .form textarea {
    width: 100%; }
  .form .g-recaptcha {
    float: none;
    margin-left: 0; }
  .form .btn {
    float: right;
    margin: 35px 0 0; } }

@media screen and (max-width: 600px) {
  .form .form__inp {
    flex-wrap: wrap; }
    .form .form__inp input[type="text"],
    .form .form__inp input[type="email"] {
      width: 100%; } }

@media screen and (max-width: 480px) {
  .form {
    padding-bottom: 130px; }
    .form .g-recaptcha {
      float: right; }
    .form .btn {
      margin-top: 12px; } }

@media screen and (max-width: 340px) {
  .form .g-recaptcha {
    transform: scale(0.9);
    transform-origin: 100% 0; }
  .form .btn {
    margin-top: 10px; } }

/* page contacts */
/* modal */
.modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99; }
  .modal .modal__layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .modal .modal__block {
    width: 420px;
    background-color: #fff;
    position: absolute;
    padding: 20px;
    left: 0;
    right: 0;
    top: 50%;
    margin: 0 auto;
    transform: translateY(-50%); }
    .modal .modal__block h4 {
      font-size: 20px;
      text-align: center;
      padding: 0 10px;
      margin: 0; }
  .modal form {
    display: block;
    padding-bottom: 130px;
    margin-top: 15px; }
    .modal form textarea,
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"],
    .modal form select {
      min-height: 44px;
      width: 100%;
      background-color: #fff;
      font-family: "ubunturegular";
      font-size: 16px;
      color: #293333;
      padding: 12px 20px;
      margin-bottom: 15px;
      border: 1px solid #d5d5d5;
      box-shadow: none; }
      .modal form textarea::placeholder,
      .modal form input[type="text"]::placeholder,
      .modal form input[type="email"]::placeholder,
      .modal form input[type="search"]::placeholder,
      .modal form input[type="password"]::placeholder,
      .modal form select::placeholder {
        color: #949999; }
    .modal form .g-recaptcha {
      float: right; }
    .modal form .btn {
      float: right;
      margin: 15px 0 0 0; }
  @media screen and (max-width: 1200px) {
    .modal .modal__block h4 {
      font-size: 18px; }
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"],
    .modal form textarea {
      min-height: 40px;
      font-size: 14px;
      padding: 10px 15px;
      margin-bottom: 10px; } }
  @media screen and (max-width: 1024px) {
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"],
    .modal form textarea {
      min-height: 35px;
      font-size: 14px;
      padding: 7px 15px;
      margin-bottom: 10px; } }
  @media screen and (max-width: 768px) {
    .modal .modal__block h4 {
      font-size: 16px; } }
  @media screen and (max-width: 500px) {
    .modal .modal__block {
      width: auto;
      left: 10px;
      right: 10px; } }
  @media screen and (max-width: 420px) {
    .modal form {
      padding-bottom: 110px; }
      .modal form .g-recaptcha {
        transform: scale(0.85);
        transform-origin: 100% 0%; }
      .modal form .btn {
        margin-top: 0; } }

.call--btn {
  position: fixed;
  bottom: 100px;
  right: 40px;
  display: table;
  background-color: #ffcc00;
  font-family: 'FontAwesome';
  border-radius: 100%;
  text-decoration: none !important;
  width: 80px;
  height: 80px;
  text-align: center;
  padding: 23px 5px 5px 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  outline: none !important;
  z-index: 1; }
  .call--btn:hover {
    animation-name: phone;
    animation-iteration-count: 1;
    animation-duration: 0.7s; }
  .call--btn i {
    display: block;
    font-size: 35px;
    color: #fff; }
  .call--btn:after, .call--btn:before {
    content: '';
    width: 90%;
    height: 90%;
    background-color: transparent;
    border: 1px solid #ffcc00;
    position: absolute;
    top: 5%;
    left: 5%;
    border-radius: 100%;
    animation-iteration-count: infinite;
    animation-duration: 1.2s;
    animation-name: ring;
    outline: none; }
  .call--btn:after {
    animation-delay: 0.6s; }
  .call--btn:before {
    animation-delay: 0.8s;
    border: 1px solid #d9af04; }
  @media screen and (max-width: 1440px) {
    .call--btn {
      margin-right: 0;
      right: 35px;
      bottom: 75px; } }
  @media screen and (max-width: 991px) {
    .call--btn {
      width: 50px;
      height: 50px;
      padding-top: 15px;
      right: 15px;
      bottom: 80px; }
      .call--btn i {
        font-size: 24px; } }
  @media screen and (max-width: 768px) {
    .call--btn {
      bottom: 50px; } }

@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0.1;
    transform: scale(1.7); } }

@keyframes phone {
  20% {
    transform: rotate(-30deg); }
  80% {
    transform: rotate(390deg); }
  100% {
    transform: rotate(360deg); } }

/* modal */
/* page content */
/* footer */
footer {
  background: #293333 url(../img/img-4.png);
  font-family: "ubuntulight";
  font-size: 18px;
  color: #d5d7d7;
  padding-top: 120px; }
  footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    footer ul li {
      margin-top: 0;
      margin-bottom: 13px; }
      footer ul li.active a {
        color: #ff4d00; }
  footer .arr--list li {
    line-height: 1.2;
    margin-top: 0; }
  footer h3 {
    width: 100%;
    color: #ffffff;
    text-transform: uppercase;
    margin: 0 0 20px; }
  footer a {
    color: #d5d7d7; }
    footer a:focus {
      color: #d5d7d7; }
    footer a:hover {
      color: #ff4d00; }
  footer > .container {
    display: flex;
    justify-content: space-between; }
  footer .footer__item {
    padding-right: 15px; }
    footer .footer__item.f--crs {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      width: 320px;
      justify-content: space-between; }
      footer .footer__item.f--crs ul {
        display: inline-block;
        vertical-align: top;
        padding-right: 25px; }
        footer .footer__item.f--crs ul:last-of-type {
          padding-right: 0; }
      footer .footer__item.f--crs .scrollY {
        max-height: 235px;
        max-width: 100%;
        margin-right: 5px; }
        footer .footer__item.f--crs .scrollY .mCSB_scrollTools {
          width: 4px; }
        footer .footer__item.f--crs .scrollY .mCSB_scrollTools .mCSB_draggerContainer {
          background-color: #fff; }
        footer .footer__item.f--crs .scrollY .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
          margin: 0; }
        footer .footer__item.f--crs .scrollY .mCSB_scrollTools .mCSB_draggerRail {
          display: none; }
    footer .footer__item.f--cts {
      padding-right: 0; }
      footer .footer__item.f--cts .col--phone a {
        margin-bottom: 5px; }
  footer .down {
    background-color: #191f1f;
    font-size: 14px;
    margin-top: 110px; }
    footer .down .container {
      height: 52px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative; }
    footer .down .socials {
      display: flex;
      width: 160px;
      justify-content: space-between;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      margin: 0 auto; }
      footer .down .socials li {
        margin: 0; }
        footer .down .socials li a {
          font-size: 24px; }
    footer .down p {
      margin: 0; }
      footer .down p span {
        color: #d14406; }
    footer .down .artmedia {
      display: flex;
      align-items: center; }
  @media screen and (max-width: 1440px) {
    footer {
      font-size: 16px;
      padding-top: 100px; }
      footer .down {
        margin-top: 90px; } }
  @media screen and (max-width: 1366px) {
    footer {
      padding-top: 80px; }
      footer .down {
        margin-top: 70px; } }
  @media screen and (max-width: 1280px) {
    footer {
      padding-top: 60px; }
      footer .down {
        margin-top: 40px; } }
  @media screen and (max-width: 1200px) {
    footer {
      font-size: 14px; }
      footer .footer__item.f--crs {
        width: 425px; }
      footer .down .socials {
        width: 140px; }
        footer .down .socials li a {
          font-size: 22px; } }
  @media screen and (max-width: 1024px) {
    footer {
      padding-top: 40px; }
      footer .down {
        margin-top: 30px; }
        footer .down .socials {
          width: 120px; }
          footer .down .socials li a {
            font-size: 20px; } }
  @media screen and (max-width: 768px) {
    footer {
      padding-top: 30px; }
      footer > .container {
        flex-wrap: wrap; }
      footer h3 {
        font-size: 16px; }
      footer .footer__item {
        width: 100%;
        margin-bottom: 15px; }
        footer .footer__item.f--crs {
          width: 100%;
          flex-wrap: wrap; }
          footer .footer__item.f--crs ul {
            width: 100%;
            padding-right: 0; }
        footer .footer__item.f--nav {
          display: none; }
        footer .footer__item.f--cts {
          margin-bottom: 0; }
      footer .down {
        margin-top: 15px; }
        footer .down .container {
          height: auto;
          flex-direction: column;
          padding: 15px; }
        footer .down .socials {
          position: relative;
          top: 0;
          transform: translateY(0);
          margin: 8px auto; } }
  @media screen and (max-width: 600px) {
    footer .footer__item.f--crs {
      flex-wrap: wrap; }
      footer .footer__item.f--crs ul {
        width: 100%;
        max-width: 100%; } }

/* footer */
